<table bgcolor="#feffff" cellpadding="0" cellspacing="0" width="100%">
    <tr>
        <td class="fs-0 h-0 lh-0"></td>
    </tr>
    <tr>
        <td valign="top">
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="rtable my-0 mx-auto" width="981">
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none p-0 bg-white text-start fw-normal" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr>
                                <th class="contenttd align-middle border-top-medium-none fw-normal border-right-medium-none text-start border-bottom-medium-none border-left-medium-none p-4 bg-transparent" width="921">
                                    <p align="center" class="fs-33 my-0 bg-transparent lh-41 text-center text-custom"><strong>Offre parrainage</strong></p>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none text-start p-0 bg-white fw-normal" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr height="772">
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-middle p-0 bg-transparent text-start fw-normal" width="981">
                                    <table border="0" cellpadding="0" cellspacing="0" class="w-100">
                                        <tr>
                                            <td align="center">
                                                <table align="center" border="0" cellpadding="0"
                                                       cellspacing="0"
                                                       class="imgtable my-0 mx-auto">
                                                    <tr>
                                                        <td align="center p-0">
                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent"> 
                                                                        <a (click)="referralCreation()"
                                                                           class="clickable border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"><img
                                                                                alt="Je Parraine J&rsquo;y gagne, mes amis aussi"
                                                                                hspace="0"
                                                                                src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_1_b8ff1d73-8175-444d-803c-f56f4c48acea.jpg"
                                                                                vspace="0" width="980"/></a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none text-start p-0 bg-white fw-normal bg-white" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-top fw-normal text-start p-0 bg-transparent"
                                    colspan="2"
                                    width="981"
                                >
                                    <div>
                                        <table border="0" cellpadding="0" cellspacing="0" class="w-100">
                                            <tr>
                                                <td align="center">

                                                    <table align="center" border="0" cellpadding="0"
                                                           cellspacing="0"
                                                           class="imgtable my-0 mx-auto" >
                                                        <tr>
                                                            <td align="center p-0">
                                                                <table border="0" cellpadding="0" cellspacing="0">
                                                                    <tr>
                                                                        <td class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent">
                                                                            <img alt="POUR VOUS : 50 &euro;(1) en bons d&rsquo;achats offerts &agrave; chaque parrainage. Offre valable pour chaque ouverture de compte de tes filleuls &agrave; la Caisse d&rsquo;Epargne Hauts de France."
                                                                                 hspace="0"
                                                                                 src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_2_d567c905-32ad-4678-8197-68963a1e81e6.jpg"
                                                                                 class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block" vspace="0" width="980"/>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none text-start p-0 bg-white fw-normal" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-top fw-normal text-start p-0 bg-transparent" width="981" colspan="2">
                                    <div>
                                        <table border="0" cellpadding="0" cellspacing="0" class="w-100">
                                            <tr>
                                                <td align="center">

                                                    <table align="center" border="0" cellpadding="0"
                                                           cellspacing="0"
                                                           class="imgtable my-0 mx-auto">
                                                        <tr>
                                                            <td align="center p-0">
                                                                <table border="0" cellpadding="0" cellspacing="0">
                                                                    <tr>
                                                                        <td class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent">
                                                                            <img alt="(1)POUR VOS FILLEULS : 50 &euro;(1) en bons d&rsquo;achats offerts pour toute 1re ouverture de compte &agrave; la Caisse d&rsquo;Epargne Hauts de France"
                                                                                 hspace="0"
                                                                                 class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"
                                                                                 src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_3_a03804fa-e584-4f00-ba19-a92963b5f973.jpg"
                                                                                 vspace="0" width="980"/>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none text-start p-0 bg-white fw-normal bg-orange" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-middle fw-normal bg-white text-start bg-transparent p-custom" width="921">
                                    <p align="center fs-33 my-0 font-custom text-center text-pink lh-41 bg-transparent"><strong>COMMENT &Ccedil;A MARCHE ?</strong></p>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none text-start p-0 fw-normal bg-light-red" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-top fw-normal text-start bg-transparent pt-20 pb-20 pl-30 pr-30 bg-light-sky" width="411">

                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td align="center">

                                                <table align="center" border="0" cellpadding="0"
                                                       cellspacing="0"
                                                       class="imgtable my-0 mx-auto">
                                                    <tr>
                                                        <td align="center" class="pb-15 pt-10 pl-10 pr-10">
                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td
                                                                    class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent" >
                                                                        <img alt=""
                                                                            class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"
                                                                             hspace="0"
                                                                             src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_4_d96fc27c-f43e-4c97-87e1-f4d08c308325.png"
                                                                             vspace="0" width="72"/></td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>

                                    <p align="center" class="my-0 font-custom text-center bg-transparent fs-18 mso-line-height-role lh-23 color-custom">
                                        <strong><span class="fs-26 lh-26">Je d&eacute;clare mes filleuls</span></strong><br/>
                                        <br/>
                                        Je saisis les coordonn&eacute;es de mes proches ag&eacute;s de 16 &agrave; 25
                                        ans, ils re&ccedil;oivent le code parrain par mail.</p>
                                </th>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-top fw-normal text-start pb-20 pt-20 pl-30 pr-30 bg-light-sky"
                                    width="450">

                                    <table border="0" cellpadding="0" cellspacing="0" class="w-100">
                                        <tr>
                                            <td align="center">

                                                <table align="center" border="0" cellpadding="0"
                                                       cellspacing="0"
                                                       class="imgtable my-0 mx-auto">
                                                    <tr>
                                                        <td align="center" class="pb-15 pt-10 pl-10 pr-10">
                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent">
                                                                        <img alt=""
                                                                             hspace="0"
                                                                             class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"
                                                                             src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_5_4c2b83b2-98a0-482d-8c8d-150923e192a7.png"
                                                                             vspace="0" width="72"/></td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>

                                    <p  align="center"
                                        class="mt-0 mb-3 text-center lh-23 bg-transparent fs-18 mso-line-height-role">
                                            <strong><span class="fs-26 lh-32" >Mon filleul ouvre son compte</span><br/>
                                            <br/>
                                    </strong>Il se rend dans l&rsquo;agence Caisse d&rsquo;Epargne Hauts de France de
                                        son choix, muni de votre code parrain.</p>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none fw-normal border-right-medium-none text-start border-bottom-medium-none border-left-medium-none px-0 pt-0 pb-30 bg-light-sky" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none p-0 text-start align-middle fw-normal bg-light-sky" width="279"></th>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none pt-20 pb-20 pr-30 pl-30 text-start align-middle fw-normal bg-light-sky" width="367">

                                    <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                        <tr>
                                            <td align="center">

                                                <table align="center" border="0" cellpadding="0"
                                                       cellspacing="0"
                                                       class="imgtable my-0 mx-auto">
                                                    <tr>
                                                        <td align="center" class="pb-15 pt-10 pl-10 pr-10">
                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent">
                                                                        <img alt=""
                                                                             hspace="0"
                                                                             class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"
                                                                             src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_6_7e6e08dc-1bba-4ddf-a0d1-2c7ff41b2043.png"
                                                                             vspace="0" width="72"/></td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>

                                    <p align="center" class="my-0 font-custom fw-normal fs-18 text-center lh-23 bg-transparent mso-line-height-role color-custom"><strong><span class="fs-26 lh-26">Nous recevons notre cadeau</span></strong><br/>
                                        <br/>
                                        D&egrave;s la validation de l&rsquo;ouverture de compte de votre filleul, vous
                                        recevez tous les 2 votre cadeau de <span class="text-light-pink"><strong>50 &euro; en bons d&rsquo;achats</strong></span>,
                                        par mail.</p>
                                </th>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-middle fw-normal p-0 text-start bg-light-sky" width="275"></th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none p-0 bg-white text-start fw-normal" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr height="435">
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-middle fw-normal p-0 text-start color-light-yellow" width="442">

                                    <table border="0" cellpadding="0" cellspacing="0" class="w-100">
                                        <tr>
                                            <td align="center">

                                                <table align="center" border="0" cellpadding="0"
                                                       cellspacing="0"
                                                       class="imgtable my-0 mx-auto">
                                                    <tr>
                                                        <td align="center p-0">
                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td
                                                                        class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent">
                                                                        <img alt=""
                                                                             hspace="0"
                                                                             class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"
                                                                             src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_7_c00c673b-4b42-40c3-80aa-b69a8b27f00b.jpg"
                                                                             vspace="0" width="442"/></td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </th>
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none align-middle fw-normal p-0 text-start color-light-yellow" width="539">

                                    <table border="0" cellpadding="0" cellspacing="0" class="w-100">
                                        <tr>
                                            <td align="center">

                                                <table align="center" border="0" cellpadding="0"
                                                       cellspacing="0"
                                                       class="imgtable my-0 mx-auto">
                                                    <tr>
                                                        <td align="center" class="p-0">
                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent">
                                                                        <a href="https://www.caisse-epargne.fr/hauts-de-france/decouvre-les-avantages-jeunes/"
                                                                           target="_blank"><img
                                                                                alt="Je d&eacute;couvre les offres jeunes"
                                                                                hspace="0"
                                                                                class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"
                                                                                src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_8_d218c69a-9c5f-440d-99bf-9e596567115e.jpg"
                                                                                title="Je d&eacute;couvre les offres jeunes" vspace="0" width="539"/></a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-white p-0 text-start fw-normal" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr height="249">
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent text-start fw-normal align-middle p-custom pb-30" width="921">

                                    <table border="0" cellpadding="0" cellspacing="0" class="w-100">
                                        <tr>
                                            <td align="center">

                                                <table align="center" border="0" cellpadding="0"
                                                       cellspacing="0"
                                                       class="imgtable my-0 mx-auto">
                                                    <tr>
                                                        <td align="center"
                                                            class="p-1"
                                                        >
                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent">
                                                                        <a (click)="referralCreation()"
                                                                           class="clickable"><img alt="Je parraine"
                                                                                                  hspace="0"
                                                                                                  class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"
                                                                                                  src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_9_75e66c4a-c4e3-4fe9-8666-62dda2b6641a.jpg"
                                                                                                  title="Je parraine" vspace="0"
                                                                                                  width="181"/></a>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>

                                    <table border="0" cellpadding="0" cellspacing="0" class="w-100">
                                        <tr>
                                            <td align="center">

                                                <table align="center" border="0" cellpadding="0"
                                                       cellspacing="0"
                                                       class="imgtable my-0 mx-auto">
                                                    <tr>
                                                        <td align="center"
                                                            class="p-0 pt-40">
                                                            <table border="0" cellpadding="0" cellspacing="0">
                                                                <tr>
                                                                    <td class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none bg-transparent">
                                                                        <img alt="#r&eacute;inventetabanque"
                                                                             hspace="0"
                                                                             class="border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none d-block"
                                                                             src="https://cdn.isifid.com/clients/CEHDF0059038FD37B4A/explanations-page/Image_10_2c271d95-32ec-4c05-9d86-5e37ae3372a5.jpg"
                                                                             title="#r&eacute;inventetabanque" vspace="0" width="125"/></td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </table>

                                            </td>
                                        </tr>
                                    </table>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
                <tr>
                    <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none text-start p-0 bg-white fw-normal" width="981">
                        <table align="left" cellpadding="0" cellspacing="0" class="w-100">
                            <tr height="660" height="660">
                                <th class="contenttd border-top-medium-none border-right-medium-none border-bottom-medium-none border-left-medium-none text-start pt-30 pl-30 pr-30 pb-30 bg-transparent fw-normal align-middle" width="921">
                                    <p align="justify" class="mb-0 font-custom mt-0 bg-transparent mso-line-height-rule lh-14 text-justify fs-11 color-custom">(1) CONDITIONS :<br/>
                                        LE PARRAIN : Majeur juridiquement capable ou mineur de plus de 16 ans avec
                                        accord des repr&eacute;sentant l&eacute;gaux, d&eacute;tenteur d&rsquo;un compte
                                        de d&eacute;p&ocirc;t &agrave; la Caisse d&rsquo;Epargne Hauts de France. L&rsquo;acquisition
                                        de la qualit&eacute; de parrain et la perception des bons d&rsquo;achats seront
                                        conditionn&eacute;es par l&rsquo;ouverture d&eacute;finitive par le filleul d&rsquo;un
                                        compte de d&eacute;p&ocirc;t.<br/>
                                        LE FILLEUL : Majeur juridiquement capable de moins de 26 ans r&eacute;volus ou
                                        mineur de plus de 16 ans avec accord des repr&eacute;sentant l&eacute;gaux r&eacute;sidant
                                        en France m&eacute;tropolitaine. Il ne doit pas &ecirc;tre pr&eacute;alablement
                                        titulaire d&rsquo;un compte de d&eacute;p&ocirc;t en Caisse d&rsquo;Epargne
                                        Hauts de France.<br/>
                                        L&rsquo;acquisition de la qualit&eacute; de filleul et la perception des bons d&rsquo;achats
                                        seront conditionn&eacute;es par l&rsquo;ouverture d&eacute;finitive par le
                                        filleul d&rsquo;un compte de d&eacute;p&ocirc;t &agrave; la Caisse d&rsquo;Epargne
                                        Hauts de France. Dans la limite d&rsquo;un seul parrainage par filleul. Sous r&eacute;serve
                                        d&rsquo;acceptation du dossier d&rsquo;ouverture du compte d&eacute;p&ocirc;t
                                        par la Caisse d&rsquo;Epargne Hauts de France.<br/>
                                        L&rsquo;OFFRE : Offre valable jusqu&rsquo;au 31/12/2022 dans la limite de 1000
                                        parrainages et un parrainage par filleul. La Caisse d&rsquo;Epargne Hauts de
                                        France se r&eacute;serve le droit de retirer ou modifier l&rsquo;offre de
                                        parrainage &agrave; tout moment.<br/>
                                        Offre r&eacute;serv&eacute;e aux personnes physiques, non cumulable avec d&rsquo;autres
                                        offres promotionnelles sauf avec l&rsquo;offre de Bienvenue<span class="fs-8 lh-10">(2).</span><br/>
                                        L&rsquo;ouverture du compte du filleul est soumise &agrave; acceptation de la
                                        Caisse d&rsquo;Epargne Hauts de France.<br/>
                                        Bons d&rsquo;achats sous forme de codes de 10 &agrave; 50 &euro; selon les
                                        enseignes partenaires. Un code attribuable par enseigne dans la limite des
                                        stocks disponibles. Bons d&rsquo;achats mis &agrave; disposition 6 mois &agrave;
                                        compter de leur attribution. La dur&eacute;e de validit&eacute; de chaque code
                                        est d&rsquo;un mois &agrave; compter de la date de sa livraison par e-mail.<br/>
                                        Les bons d&rsquo;achats sont &eacute;mis imm&eacute;diatement par email d&egrave;s
                                        l&rsquo;ouverture de compte du filleul et l&rsquo;activation du lien
                                        parrain/filleul valid&eacute; en agence.<br/>
                                        Le compte de d&eacute;p&ocirc;t est garanti par le fonds de garantie des d&eacute;p&ocirc;ts
                                        et de r&eacute;solution.</p>
                                    <p align="justify" class="mb-0 font-custom mt-0 bg-transparent mso-line-height-rule lh-14 text-justify fs-11 color-custom"><br/>
                                        (2) Offre de bienvenue non cumulable avec d&rsquo;autres offres promotionnelles
                                        sauf avec l&rsquo;offre Parrainage<span class="fs-8 lh-10">(1)</span> et sous conditions,
                                        pour les personnes &acirc;g&eacute;es de 16 &agrave;, valables jusqu&rsquo;au
                                        31/12/2022 pour toute souscription d&rsquo;une Formule Individuelle, sous r&eacute;serve
                                        d&rsquo;acceptation de l&rsquo;ouverture par la Caisse d&rsquo;Epargne Hauts de
                                        France. Au-del&agrave; des 12 mois, l&rsquo;offre sera factur&eacute;e au tarif
                                        en vigueur &agrave; cette date.</p>
                                    <p align="justify" class="mb-0 font-custom mt-0 bg-transparent mso-line-height-rule lh-14 text-justify fs-11 color-custom"><br/>
                                        La Caisse d&rsquo;Epargne Hauts de France recueille des donn&eacute;es &agrave;
                                        caract&egrave;re personnel vous concernant et met en &oelig;uvre des mesures
                                        techniques et organisationnelles appropri&eacute;es pour s&rsquo;assurer que les
                                        traitements de donn&eacute;es &agrave; caract&egrave;re personnel sont effectu&eacute;s
                                        conform&eacute;ment &agrave; la l&eacute;gislation applicable. Les donn&eacute;es
                                        recueillies via le pr&eacute;sent formulaire sont obligatoires.<br/>
                                        &Agrave; d&eacute;faut, votre demande ne pourrait pas &ecirc;tre trait&eacute;e
                                        ou son traitement s&rsquo;en trouverait retard&eacute;. Vos donn&eacute;es sont
                                        trait&eacute;es pour les finalit&eacute;s suivantes :<br/>
                                        - Prise de contact en votre nom aupr&egrave;s de vos potentiels filleuls afin de
                                        leur proposer l&rsquo;offre de parrainage.<br/>
                                        - Vous faire b&eacute;n&eacute;ficier de l&rsquo;offre de parrainage le cas
                                        &eacute;ch&eacute;ant, lorsque toutes les conditions de l&rsquo;offre sont
                                        remplies.</p>
                                    <p align="justify" class="mb-0 font-custom mt-0 bg-transparent mso-line-height-rule lh-14 text-justify fs-11 color-custom"><br/>
                                        Destinataire(s) :<br/>
                                        Vos donn&eacute;es sont destin&eacute;es &agrave; ISIFID, soci&eacute;t&eacute;
                                        par actions simplifi&eacute;e &agrave; associ&eacute; unique (SASU) au capital
                                        de 200 000 (deux cent mille) euros, ayant son si&egrave;ge 9 rue des Colonnes,
                                        75002 Paris, inscrite au Registre du Commerce et des Soci&eacute;t&eacute;s de
                                        Nanterre sous le num&eacute;ro 834 210 312, sous-traitant.<br/>
                                        Dur&eacute;e de conservation : la dur&eacute;e de conservation des donn&eacute;es
                                        est de 2 ans pour les finalit&eacute;s li&eacute;es &agrave; l&rsquo;offre de
                                        parrainage.<br/>
                                        Droit des personnes concern&eacute;es :<br/>
                                        Vous b&eacute;n&eacute;ficiez d&rsquo;un droit d&rsquo;acc&egrave;s &agrave; vos
                                        donn&eacute;es &agrave; caract&egrave;re personnel. Dans les conditions pr&eacute;vues
                                        par la loi, vous pouvez &eacute;galement demander une limitation du traitement,
                                        la rectification ou l&rsquo;effacement des donn&eacute;es vous concernant, ainsi
                                        que leur portabilit&eacute;, ou communiquer des directives sur le sort de ces
                                        donn&eacute;es en cas de d&eacute;c&egrave;s. Si le traitement est fond&eacute;
                                        sur votre consentement, vous disposez du droit de retirer votre consentement. Si
                                        le traitement est fond&eacute; sur l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime
                                        de la Caisse d&rsquo;Epargne Hauts de France, vous pouvez vous opposer &agrave;
                                        ce traitement si vous justifiez de raisons propres &agrave; votre situation.
                                        Vous disposez &eacute;galement du droit de vous opposer au traitement de vos
                                        donn&eacute;es &agrave; des fins de prospection commerciale. Ces droits peuvent,
                                        sous r&eacute;serve de justifier de votre identit&eacute; par la production d&rsquo;une
                                        copie d&rsquo;identit&eacute;, &ecirc;tre exerc&eacute;s &agrave; tout moment
                                        &agrave; l&rsquo;adresse suivante :<br/>
                                        Par courrier postal : Caisse d&rsquo;Epargne Hauts de France Service Relation
                                        Client&egrave;le D&eacute;l&eacute;gu&eacute; &agrave; la Protection des donn&eacute;es
                                        8 Rue Vad&eacute; 80064 Amiens Cedex 9.<br/>
                                        Par courriel : <a href="mailto:service.client@hdf.caisse-epargne.fr" class="text-decoration-none color-custom"
                                                          target="_blank">service.client&#64;hdf.caisse-epargne.fr</a><br/>
                                        Si vous souhaitez en savoir plus ou contacter notre D&eacute;l&eacute;gu&eacute;
                                        &agrave; la Protection des Donn&eacute;es, vous pouvez nous &eacute;crire
                                        &agrave; l&rsquo;adresse suivante : <a
                                                href="mailto:delegue-protection-donnees@hdf.caisse-epargne.fr" class="text-decoration-none color-custom"
                                                target="_blank"
                                                title="">delegue-protection-donnees&#64;hdf.caisse-epargne.fr</a> R&eacute;clamations
                                        : les personnes concern&eacute;es ont le droit d&rsquo;introduire une r&eacute;clamation
                                        aupr&egrave;s d&rsquo;une autorit&eacute; de contr&ocirc;le en charge de la
                                        protection des personnes physiques &agrave; l&rsquo;&eacute;gard du traitement
                                        des donn&eacute;es &agrave; caract&egrave;re personnel. En France, l&rsquo;autorit&eacute;
                                        de contr&ocirc;le est : Commission Nationale de l&rsquo;Informatique et des
                                        Libert&eacute;s (CNIL) 3 Place de Fontenoy TSA 80 715 75 334 PARIS Cedex
                                        07.<br/>
                                        Pour plus d&rsquo;information, consultez notre notice d&rsquo;information sur la
                                        protection des donn&eacute;es personnelles &agrave; tout moment sur notre site
                                        <a href="https://www.caisse-epargne.fr/da/file/fre-NF/360030"
                                        class="text-decoration-none color-custom"
                                           target="_blank">https://www.caisse-epargne.fr/da/file/fre-NF/360030</a>
                                        (co&ucirc;t
                                        de connexion selon votre fournisseur d&rsquo;acc&egrave;s), ou sur simple
                                        demande aupr&egrave;s de votre agence.<br/>
                                    </p>
                                </th>
                            </tr>
                        </table>
                    </th>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <td class="fs-0 lh-0 h-8"></td>
    </tr>
</table>
