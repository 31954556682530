import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {MsServicesSponsorshipService} from '@isifid/core';

@Component({
    selector: 'app-custom-code',
    templateUrl: './custom-code.component.html',
    styleUrls: ['./custom-code.component.scss']
})
export class CustomCodeComponent implements OnInit {
    protected readonly environment = environment;
    codeForm: FormGroup;
    sponsorCreation: boolean;

    constructor(
        public readonly sponsorshipService: SponsorshipService,
        private readonly formBuilder: FormBuilder,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly sponsorService: SponsorService
    ) {
        // If fragment = creation, set sponsorCreation to true
        this.route.fragment.subscribe((fragment) => {
            if (fragment === 'creation') this.sponsorCreation = true;
        });
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm() {
        this.codeForm = this.formBuilder.group({
            id: [this.sponsorService.sponsorshipUser.id, Validators.required],
            consumerId: [this.sponsorService.sponsorshipUser.consumerId, Validators.required],
            codeCustomised: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50), Validators.pattern('^[A-Za-z0-9]+$')]]
        });
        // If the user already has a custom code, set it and disable the form
        if (this.sponsorService.sponsorshipUser.codeCustomised) {
            this.codeForm.get('codeCustomised').setValue(this.sponsorService.sponsorshipUser.codeCustomised);
            this.codeForm.get('codeCustomised').disable();
        }
    }

    setCustomCode() {
        if (this.codeForm.invalid || this.sponsorService.sponsorshipUser.codeCustomised) return;

        const sponsorData = {
            id: this.codeForm.get('id').value,
            codeCustomised: this.codeForm.get('codeCustomised').value,
            consumerId: this.codeForm.get('consumerId').value
        };
        // Check if the code is not already used
        this.msServicesSponsorshipService.getSponsorshipUserByCode(sponsorData.codeCustomised).subscribe({
            // If already used, set error
            next: () => this.codeForm.get('codeCustomised').setErrors({alreadyUsed: true}),
            error: (e) => {
                // If not used, update the code
                if (e.status === 404) {
                    this.msServicesSponsorshipService.updateSponsorshipUser(this.codeForm.get('id').value, sponsorData).subscribe(() => {
                        this.sponsorService.initByConsumerId(this.sponsorService.sponsorshipUser.consumerId.toString()).subscribe(() => {
                            this.sponsorService.sendAccessByConsumerId(this.sponsorService.consumer.email, this.sponsorService.sponsorshipUser.settingsId, this.sponsorService.consumer.id).subscribe(() => {
                                this.router.navigate(['/parrain/inviter']).then();
                            });
                        });
                    });
                } else console.error('Error while checking if the custom code is already used');
            }
        });
    }

    later() {
        this.sponsorService.sendAccessByConsumerId(this.sponsorService.consumer.email, this.sponsorService.sponsorshipUser.settingsId, this.sponsorService.consumer.id).subscribe(() => {
            this.router.navigate(['/parrain/inviter']).then();
        });
    }
}
