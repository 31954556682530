import {Injectable} from '@angular/core';
import {finalize, Observable} from 'rxjs';
import {
    AuthenticationService,
    CacheService,
    ConfigurationService,
    Consumer,
    MsConsumersService,
    MsServicesSponsorshipService,
    SponsorshipUser,
} from '@isifid/core';
import { TrackingServiceSponsorship } from './tracking-service-sponsorship.service';

@Injectable()
export class SponsorService {
    sponsorshipUser: SponsorshipUser;
    consumer: Consumer;

    constructor(
    private readonly cacheService: CacheService,
    private readonly configurationService: ConfigurationService,
    private readonly msConsumersService: MsConsumersService,
    private readonly authenticationService: AuthenticationService,
    private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
    private readonly trackingService: TrackingServiceSponsorship
    ) {
    }

    destroy() {
        this.consumer = null;
    }

    initEntitiesFromCache() {
        this.consumer = this.cacheService.getContent('consumer');
        this.sponsorshipUser = this.cacheService.getContent('sponsorshipUser');
    }

    initSponsorshipUser(sponsorshipUser: SponsorshipUser = null): void {
        if (sponsorshipUser) this.sponsorshipUser = sponsorshipUser;
        this.cacheService.addPermanentContent('sponsorshipUser', sponsorshipUser);
        this.initEntitiesFromCache();
    }

    initByConsumerId(consumerId: string): Observable<any> {
        return new Observable((observer) => {
            this.msServicesSponsorshipService.getSponsorshipUserByConsumerId(consumerId)
                .pipe(finalize(() => {
                    this.msConsumersService.getConsumer(consumerId).subscribe({
                        next: (consumer) => {
                            this.initConsumer(consumer);
                            observer.next();
                            observer.complete();
                        }, error: (err) => observer.error(err)
                    });
                })
                ).subscribe({
                    next: (sponsorshipUser) => this.initSponsorshipUser(sponsorshipUser),
                    error: () => this.initSponsorshipUser(null)
                });
        });
    }

    initBySponsorCode(code: string): Observable<any> {
        return new Observable((observer) => {
            this.msServicesSponsorshipService.getSponsorshipUserByCode(code).subscribe({
                next: (sponsorshipUser) => {
                    this.msConsumersService.getConsumer(sponsorshipUser.consumerId)
                        .pipe(finalize(() => {
                            this.initSponsorshipUser(sponsorshipUser);
                            observer.next();
                            observer.complete();
                        })
                        ).subscribe({
                            next: (consumer) => this.initConsumer(consumer),
                            error: (error) => observer.error(error)
                        });
                }, error: (error) => observer.error(error)
            });
        });
    }

    create(consumer, sponsorshipUser): Observable<boolean> {
        return new Observable((observer) => {
            // Create consumer
            this.msConsumersService.createConsumer(consumer, true).subscribe({
                next: (res) => {
                    const consumer: Consumer = res.body;
                    // If the consumer already exists
                    if (res.status === 409 || res.status === 200) {
                        observer.next(false);
                        observer.complete();
                    } else if (res.status === 201) {
                        sponsorshipUser.consumerId = consumer.id;

                        // Setting the source of the user
                        let source = this.trackingService.getUtmParam('utm_source');
                        if (!source) source = 'online';

                        let medium = this.trackingService.getUtmParam('utm_medium');
                        if (!medium && consumer.giftUserId) medium = 'qr-code';

                        // Login consumer
                        this.authenticationService.loginByConsumerToken(consumer.purlToken).subscribe({
                            next: () => {
                                const data = {
                                    consumerId: sponsorshipUser.consumerId,
                                    clientId: consumer.clientId,
                                    allowCommunications: sponsorshipUser.allowCommunications,
                                    settingsId: sponsorshipUser.settingsId,
                                    source: source,
                                    medium: medium,
                                    campaign: this.trackingService.getUtmParam('utm_campaign')
                                };
                                // Create sponsor
                                this.createSponsor(data, observer);
                            }, error: (err) => observer.error(err)
                        });
                    }
                }, error: (err) => observer.error(err)
            });
        });
    }

    private createSponsor(data, observer) {
        this.createSponsorAndSendAccess(data).subscribe({
            next: (sponsorCreated) => observer.next(sponsorCreated),
            error: (err) => observer.error(err),
            complete: () => observer.complete()
        });
    }

    createSponsorAndSendAccess(sponsorshipUserData): Observable<boolean> {
        return new Observable((observer) => {
            this.msServicesSponsorshipService.createSponsorshipUser(sponsorshipUserData).subscribe({
                next: (sponsorshipUser: SponsorshipUser) => {
                    // Init sponsor
                    this.initSponsorshipUser(sponsorshipUser);
                    this.initByConsumerId(sponsorshipUserData.consumerId).subscribe();
                    observer.next(!!sponsorshipUser);
                }, error: (err) => observer.error(err),
                complete: () => observer.complete()
            });
        });
    }

    updateConsumer(consumerData): Observable<any> {
        return new Observable((observer) => {
            this.msConsumersService.updateConsumer(this.consumer.id, consumerData).subscribe({
                next: (consumer: Consumer) => {
                    this.consumer = consumer;
                    this.cacheService.addPermanentContent('consumer', consumer);
                    observer.next();
                }, error: (err) => observer.error(err),
                complete: () => observer.complete()
            });
        });
    }

    sendAccessByConsumerId(email: string, settingsId: number, consumerId: number = null): Observable<any> {
        const sponsorshipUser = new SponsorshipUser();
        sponsorshipUser.consumerId = consumerId;
        sponsorshipUser.settingsId = settingsId;
        return this.msServicesSponsorshipService.sendSponsorshipUserAccess(sponsorshipUser.consumerId);
    }

    sendAccessByEmail(email: string): Observable<any> {
        return this.msServicesSponsorshipService.sendSponsorshipUserAccessEmail(email);
    }

    private initConsumer(c: Consumer): void {
        const consumer = new Consumer();
        Object.assign(consumer, c);
        this.consumer = consumer;
        this.cacheService.addPermanentContent('consumer', consumer);
        this.initEntitiesFromCache();
    }

    getSponsorCode(): string | null {
        if (!this.sponsorshipUser) return null;

        // By default, return the customised code
        if (this.sponsorshipUser.codeCustomised) return this.sponsorshipUser.codeCustomised;
        return this.sponsorshipUser.code;
    }
}
