import {AbstractControl, FormControl, ValidatorFn} from '@angular/forms';

export class CustomValidator {

    static firstNameLastName(c: FormControl): Record<string, any> {
        const nameRegex = /^([a-zA-ZàáâäæçéèêëîïôœùûüÿÀÂÄÆÇÉÈÊËÎÏÔŒÙÛÜŸ \-']+)$/;
        const valid = nameRegex.test(c.value);
        return c.value < 1 || valid ? null : {isName: true};
    }

    static ageRangeValidator(min: number, max: number): ValidatorFn {
        return (c: AbstractControl): Record<string, boolean> | null => {
            const age = new Date().getFullYear() - new Date(c.value).getFullYear();
            if (age !== undefined && (isNaN(age) || age < min || age > max)) {
                return {matDatepickerParse: true};
            }
            return null;
        };
    }

    static requiredAgency(c: FormControl): Record<string, any> {
        return c.value ? null : {requiredAgency: true};
    }
}
