import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {appointmentsRouting} from './sponsored.routes';
import {MakeComponent} from './appointment/make/make.component';
import {ConfirmationComponent} from './appointment/confirmation/confirmation.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {GoogleMapsModule} from '@angular/google-maps';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
    imports: [
        appointmentsRouting,
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatDatepickerModule,
        MatSelectModule,
        GoogleMapsModule
    ],
    declarations: [
        MakeComponent,
        ConfirmationComponent
    ],
    exports: [
        MakeComponent,
        ConfirmationComponent
    ],
    providers: []
})
export class SponsoredModule {
}
