import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';

import {MakeComponent} from './appointment/make/make.component';
import {ConfirmationComponent} from './appointment/confirmation/confirmation.component';
import {SponsoredModule} from './sponsored.module';
import {HomeComponent} from './home/home.component';
import {authGuardChildFn, authGuardFn, trackGuardChildFn, trackGuardFn} from '@isifid/core';
import {sponsorshipGuardFn} from '../../shared/helpers/sponsorship.guard';

const sponsoredRoutes: Routes = [
    {
        path: 'filleul/accueil',
        canActivate: [trackGuardFn, authGuardFn, sponsorshipGuardFn],
        component: HomeComponent
    },
    {
        path: 'filleul/rendez-vous',
        canActivateChild: [trackGuardChildFn, authGuardChildFn],
        children: [
            {
                path: 'confirmation',
                component: ConfirmationComponent
            },
            {
                path: '',
                canActivate: [sponsorshipGuardFn],
                component: MakeComponent
            }
        ]
    }
];

export const appointmentsRouting: ModuleWithProviders<SponsoredModule> = RouterModule.forChild(sponsoredRoutes);
