import {Component} from '@angular/core';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {MsServicesSponsorshipService} from '@isifid/core';
import {finalize} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'app-unsubscribe',
    templateUrl: './unsubscribe.component.html',
    styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent {
    isLoading: boolean = true;
    constructor(
        private router: Router,
        private readonly sponsorService: SponsorService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService
    ) {
        if (this.sponsorService.sponsorshipUser.allowCommunications) {
            this.msServicesSponsorshipService.updateSponsorshipUser(this.sponsorService.sponsorshipUser.id, {allowCommunications: false})
                .pipe(finalize(() => this.isLoading = false))
                .subscribe();
        } else {
            this.router.navigate(['/parrain/compte/informations']).then();
        }
    }
}
