<footer class="text-justify" id="footer">
    <div class="col-xs-11 m-5">
        @if (content) {
            <p [innerHTML]="content.terms" class="mb-3 legal-notice"></p>
            <p [innerHTML]="content.legalNotice" class="legal-notice"></p>
        }
        @if (settings.matomoSiteId) {
            <p class="legal-notice">
                <a routerLink="/cookies">Politique relative aux cookies</a>
            </p>
        }
    </div>

    <div class="border-top-custom text-center">
        @if (content) {
            <img [src]="logoUrl" alt="logo" class="my-3" height="" width="">
        }
    </div>
</footer>
