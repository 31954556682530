<div class="container-fluid p-0 m-0 d-flex flex-column">
    <app-header />

    <div class="flex-fill">
        <div class="step col-10 col-md-6 mx-auto my-5 p-5 text-center">
            @if (!isLoading) {
                <div class="row my-3">
                    <div class="col px-md-5 text-center">
                        <p class="custom-text h2">Vous avez été désabonné(e)</p>
                        <span>Vous ne recevrez plus ce type d’e-mails.</span>

                        <div class="mt-5">
                            <a class="btn btn-primary mt-2 p-3 text-nowrap text-decoration-none" data-cy="information"
                               routerLink="/parrain/compte/informations">
                               Modifier mes informations personnelles
                            </a>
                        </div>
                    </div>
                </div>
            } @else {
                <div><em class="fas fa-spinner fa-spin fs-2"></em></div>
            }
        </div>
    </div>
    <app-footer />
</div>
