import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {CacheService, MsServicesSponsorshipService, SponsorshipContent, SponsorshipSettings} from '@isifid/core';

@Injectable()
export class SponsorshipService {
    public rewardWithCash: boolean;
    public shareBySms: boolean;
    public explicitSponsorAuthorizationContactSponsor: boolean;
    public customExplanationsPage: boolean;
    public content: SponsorshipContent;
    // Allow to set different modes by the bootstrap service:
    // - Sponsor
    // - Sponsored
    // - Public
    // - Advisor (probably coming from gift)
    mode: 'sponsor' | 'sponsored' | 'public' | 'advisor';
    public settings: SponsorshipSettings;

    constructor(
        private cacheService: CacheService,
        private msServicesSponsorshipService: MsServicesSponsorshipService,
    ) {
    }

    destroy() {
        this.content = null;
        this.settings = null;
        this.mode = null;
        this.rewardWithCash = false;
        this.shareBySms = false;
        this.explicitSponsorAuthorizationContactSponsor = false;
        this.customExplanationsPage = false;
    }

    initEntitiesFromCache() {
        this.content = this.cacheService.getContent('content');
        this.settings = this.cacheService.getContent('settings');
        this.mode = this.cacheService.getContent('mode', false);
        this.rewardWithCash = this.settings?.configuration?.['cashRewardEnabled'];
        this.shareBySms = this.settings?.configuration?.['shareBySms'];
        this.explicitSponsorAuthorizationContactSponsor = this.settings?.configuration?.['explicitSponsorAuthorizationContactSponsor'];
        this.customExplanationsPage = this.settings?.configuration?.['customExplanationsPage'];
    }

    setMode(mode) {
        this.cacheService.addPermanentContent('mode', mode, false);
        this.mode = mode;
    }

    getContent(): SponsorshipContent {
        return this.content;
    }

    getSettings(): SponsorshipSettings {
        return this.settings;
    }

    initContentAndSettings(settingsId): Observable<any> {
        return new Observable(observer => {
            // Init the settings & the content
            forkJoin([
                this.msServicesSponsorshipService.getSettings(settingsId),
                this.msServicesSponsorshipService.getContent(settingsId)
            ]).subscribe({
                next: ([settings, content]) => {
                    content = this.replaceVariablesInProperties(content, settings);
                    if (settings.configuration) settings.configuration = JSON.parse(settings.configuration);
                    if (
                        settings.appointmentEmailMethod === 'direct_redirection' &&
                        settings.appointmentUrl &&
                        this.mode === 'sponsored'
                    ) {
                        location.href = settings.appointmentUrl;
                    }
                    this.cacheService.addPermanentContent('settings', settings);
                    this.cacheService.addPermanentContent('content', content);
                    this.initEntitiesFromCache();
                    observer.next();
                    observer.complete();
                }, error: (error) => observer.error(error)
            });
        });
    }

    getMinDate(): Date {
        const minDate = new Date();
        if (this.settings.sponsorAgeMax) minDate.setFullYear(new Date().getFullYear() - this.settings.sponsorAgeMax);
        else minDate.setFullYear(new Date().getFullYear() - 120);

        return minDate;
    }

    getMaxDate(): Date {
        const maxDate = new Date();
        if (this.settings.sponsorAgeMin) maxDate.setFullYear(new Date().getFullYear() - this.settings.sponsorAgeMin);
        else maxDate.setFullYear(new Date().getFullYear() - 18);

        return maxDate;
    }

    private replaceVariablesInProperties(content, settings) {
        const contentWithVariables = {...content, ...settings};
        const map = new Map<string, string>(Object.entries(contentWithVariables));
        map.forEach((value, key) => {
            if (value) {
                map.forEach((valueToInject, keyToInject) => {
                    value = value.toString().replace(new RegExp('\\${' + keyToInject + '}', 'g'), valueToInject);
                });
                map.set(key, value);
                contentWithVariables[key] = value;
            }
        });
        // Remove settings
        for (const [key] of Object.entries(settings)) {
            delete contentWithVariables[key];
        }
        return contentWithVariables;
    }
}
