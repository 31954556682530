import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {CreateComponent} from './account/create/create.component';
import {InviteComponent} from './invite/invite.component';
import {SponsorModule} from './sponsor.module';
import {HistoryComponent} from './account/history/history.component';
import {InformationsComponent} from './account/informations/informations.component';
import {LoginComponent} from './account/login/login.component';
import {ValidateComponent} from './account/validate/validate.component';
import {authGuardChildFn, trackGuardChildFn} from '@isifid/core';
import {CustomCodeComponent} from './account/custom-code/custom-code.component';
import {sponsorshipGuardFn} from '../../shared/helpers/sponsorship.guard';
import {UnsubscribeComponent} from './account/unsubscribe/unsubscribe.component';

const sponsorRoutes: Routes = [
    {
        path: 'parrain',
        canActivateChild: [trackGuardChildFn, authGuardChildFn],
        children: [
            {
                path: 'inviter',
                canActivate: [sponsorshipGuardFn],
                component: InviteComponent
            },
            {
                path: 'login',
                component: LoginComponent
            }
        ]
    },
    {
        path: 'parrain/compte',
        canActivateChild: [trackGuardChildFn, authGuardChildFn],
        children: [
            {
                path: 'historique',
                canActivate: [sponsorshipGuardFn],
                component: HistoryComponent
            },
            {
                path: 'validation',
                canActivate: [sponsorshipGuardFn],
                component: ValidateComponent
            },
            {
                path: 'informations',
                canActivate: [sponsorshipGuardFn],
                component: InformationsComponent
            },
            {
                path: 'unsubscribe',
                canActivate: [sponsorshipGuardFn],
                component: UnsubscribeComponent
            },
            {
                path: 'creation',
                component: CreateComponent
            },
            {
                path: 'personnalisation',
                component: CustomCodeComponent
            }
        ]
    }
];

export const sponsorshipRouting: ModuleWithProviders<SponsorModule> = RouterModule.forChild(sponsorRoutes);
