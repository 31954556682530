<app-header />

<app-sponsor-shared-banner />

<div class="container-fluid px-md-5 py-5">
    <div class="col col-md-10 mx-auto account">
        <div class="row g-0 py-4">
            <div class="col pt-1">
                <h3 class="h3 custom-text text-center custom-text my-auto">Suivi de vos Parrainages</h3>
            </div>
        </div>
        <div class="row g-0 bg-grey pt-3">
            <div class="col">
                <p class="text-center text-secondary h5 font-weight-bold">
                    <span class="me-2">FILLEULS VALIDÉS</span>
                    <em aria-hidden="true" class="fas fa-check"></em>
                </p>
            </div>
        </div>

        @for (sponsored of rewards; track sponsored.id; let index = $index) {
            <div class="row g-0 text-center text-secondary py-3 border">
                <div class="col my-auto">
                    <span class="align-top">{{sponsored.firstName | titlecase}} {{sponsored.lastName | titlecase}}</span>
                </div>
                <div class="d-none d-md-block col my-auto">
                    <span>{{sponsored.email}}</span>
                </div>
                <div class="d-none d-lg-block col my-auto">
                    <span>{{sponsored.mobile}}</span>
                </div>
                <div class="col my-auto">
                    @if (
                        sponsorshipService.getSettings().sponsoredAmount &&
                        (
                            index < sponsorshipService.getSettings().maxSponsoredPerSponsor ||
                            !sponsorshipService.getSettings().maxSponsoredPerSponsor
                        )
                    ) {
                        <span>
                            {{sponsorshipService.getSettings().sponsoredAmount}}€
                        </span>
                    }
                </div>
                <div class="col my-auto">
                    @if (sponsored.accountOpenedAt) {
                        <span>le {{sponsored.accountOpenedAt | date:'shortDate'}}</span>
                    }
                </div>
            </div>
        }
        @if (!rewards || rewards.length === 0) {
            <div class="row g-0 text-center text-secondary py-3 border">
                <div class="col m-auto">
                    <span>Pas encore de filleul</span>
                </div>
            </div>
        }

        <div class="row g-0 bg-grey pt-3">
            <div class="col">
                <p class="text-center text-secondary h5 font-weight-bold">
                    <span class="me-2">FILLEULS EN ATTENTE</span>
                    <em aria-hidden="true" class="fas fa-comment-dots"></em>
                </p>
            </div>
        </div>

        @for (sponsored of invitations; track index; let index = $index; let first = $first) {
            <div class="row g-0 text-center text-secondary py-3 border">
                <div class="col my-auto">
                    <span class="align-top">{{sponsored.firstName + ' ' + sponsored.lastName}}</span>
                </div>
                <div class="d-none d-md-block col my-auto">
                    <span>{{sponsored.email}}</span>
                </div>
                <div class="d-none d-md-block col my-auto">
                    <span>{{sponsored.mobile}}</span>
                </div>
                <div class="col my-auto">
                    @if ((sponsored.email || sponsored.mobile) && !sponsored['resend']) {
                        <span (click)="reSendInvite(sponsored); sponsored['resend'] = true"
                              (keydown)="reSendInvite(sponsored); sponsored['resend'] = true"
                              class="text-underline text-secondary clickable"
                              data-cy="resend">Relancer </span>
                    }
                    @if (sponsored['resend']) {
                        <span class="text-secondary" data-cy="sent">Relancé</span>
                    }
                </div>
            </div>
        }
        @if (!invitations || invitations.length === 0) {
            <div class="row g-0 text-center text-secondary py-3 border">
                <div class="col m-auto">
                    <span>Pas encore de filleul</span>
                </div>
            </div>
        }

        <div class="row pt-5">
            <div class="col">
                <p class="text-center"><a class="custom-text" routerLink="/parrain/inviter">X Retour</a></p>
            </div>
        </div>
    </div>
</div>

<app-footer />
