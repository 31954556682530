import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {SponsorService} from '../../../shared/services/sponsor.service';
import {AuthenticationService, MsServicesSponsorshipService} from '@isifid/core';
import {DOCUMENT} from '@angular/common';

@Component({
    templateUrl: './autologin.component.html'
})
export class AutologinComponent {
    loading: boolean;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly sponsorService: SponsorService,
        private readonly router: Router,
        @Inject(PLATFORM_ID) private platformId,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.msServicesSponsorshipService.getAutologin().subscribe({
            next: (settings) => {
                if (settings) {
                    const args = this.document.defaultView.btoa(settings.loginEmail + ',' + settings.loginPassword + ',' + settings.id);
                    this.router.navigate(['/auth/' + args]).then();
                } else {
                    this.router.navigate(['/session-has-expired']).then();
                }
            },
            error: () => {
              this.router.navigate(['/session-has-expired']).then();
            }
        });
    }
}
