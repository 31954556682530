import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {SessionHasExpiredComponent} from './session-has-expired/session-has-expired.component';
import {ErrorComponent} from './error/error.component';
import {CookiesComponent} from './cookies/cookies.component';
import {PublicModule} from './public.module';
import {AuthComponent} from './auth/auth.component';
import {LogoutComponent} from './logout/logout.component';
import {AutologinComponent} from './autologin/autologin.component';
import {trackGuardFn} from '@isifid/core';

const publicRoutes: Routes = [
    {
        path: '',
        component: AutologinComponent
    },
    {
        path: 'session-has-expired',
        canActivate: [trackGuardFn],
        component: SessionHasExpiredComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'error',
        canActivate: [trackGuardFn],
        component: ErrorComponent
    },
    {
        path: 'cookies',
        canActivate: [trackGuardFn],
        component: CookiesComponent
    },
    {
        path: 'auth',
        children: [
            {
                path: ':hash',
                component: AuthComponent
            },
            {
                path: '**',
                component: AuthComponent
            }
        ]
    },
    {
        path: 'c/:sponsorCode',
        component: AuthComponent
    }
];

export const publicRouting: ModuleWithProviders<PublicModule> = RouterModule.forChild(publicRoutes);
