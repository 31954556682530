import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {SponsorService} from '../../../../shared/services/sponsor.service';
import {CustomValidator} from '../../../../shared/helpers/custom.validator';
import {environment} from '../../../../../environments/environment';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {DateAdapter} from '@angular/material/core';
import {SafeUrl} from '@angular/platform-browser';
import {
    CacheService,
    Consumer,
    FormValidators,
    MobileService,
    MsConsumersService,
    MsServicesSponsorshipService,
    SponsorshipSettings,
    SponsorshipUser
} from '@isifid/core';
import {switchMap} from 'rxjs';

@Component({
    selector: 'app-sponsor-validate',
    templateUrl: './validate.component.html',
    styleUrls: ['./validate.component.scss']
})
export class ValidateComponent {
    cdnUrl = environment.cdnUrl;
    consumerForm: FormGroup;
    loading: boolean;
    maxDate: Date;
    minDate: Date;
    logo: SafeUrl;
    banner: SafeUrl;
    settings: SponsorshipSettings;
    private readonly consumer: Consumer;

    constructor(
        public readonly sponsorshipService: SponsorshipService,
        private readonly mobileService: MobileService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly router: Router,
        private readonly sponsorService: SponsorService,
        private readonly msConsumersService: MsConsumersService,
        private readonly cacheService: CacheService,
        private dateAdapter: DateAdapter<Date>
    ) {
        this.dateAdapter.setLocale('fr');
        this.settings = sponsorshipService.getSettings();
        this.loading = false;
        this.minDate = this.sponsorshipService.getMinDate();
        this.maxDate = this.sponsorshipService.getMaxDate();
        this.logo = `${environment.cdnUrl}/clients/${this.settings.templateHash}/logo.png`;
        this.banner = `${environment.cdnUrl}/clients/${this.settings.templateHash}/banner.jpg`;
        this.consumer = this.sponsorService.consumer;
        const gender = this.consumer.gender ? this.consumer.gender.toString() : '4';
        this.consumerForm = this.formBuilder.group({
            gender: [gender, Validators.required],
            firstName: [this.consumer.firstName, [Validators.required, CustomValidator.firstNameLastName]],
            lastName: [this.consumer.lastName, [Validators.required, CustomValidator.firstNameLastName]],
            email: [this.consumer.email, [Validators.required, Validators.email]],
            mobile: [this.consumer.mobile, [Validators.required, this.formValidators.isFrenchMobilePhone]],
            checkAge: [true, [Validators.requiredTrue]],
            allowCommunications: [this.settings.optOutEnabled]
        });
    }

    submit(): void {
        if (this.consumerForm.invalid) {
            this.showErrors();
            return;
        }
        this.loading = true;

        // Check optOutEnabled
        const allowCommunications = this.consumerForm.get('allowCommunications').value ? 1 : 0;

        const consumerData = {};
        for (const property in this.consumerForm.getRawValue()) {
            if (property === 'mobile') {
                if (this.consumerForm.get(property).value && this.consumerForm.get(property).value !== this.consumer) {
                    consumerData[property] = this.mobileService.formatMobile(this.consumerForm.get(property).value);
                }
            } else if (this.consumerForm.get(property).value !== this.consumer[property]) consumerData[property] = this.consumerForm.get(property).value;
        }

        this.msConsumersService.updateConsumer(this.consumer.id, consumerData).subscribe(() => {
            // only add the missing property
            this.msServicesSponsorshipService.getSponsorshipUserByConsumerId(this.consumer.id)
                .pipe(
                    switchMap((sponsorshipUser: SponsorshipUser) =>
                        this.msServicesSponsorshipService.updateSponsorshipUser(sponsorshipUser.id, {allowCommunications: allowCommunications})
                    )
                )
                .subscribe({
                    next: () => this.reloadData()
                });
        });
    }

    private reloadData(): void {
        this.sponsorService.initByConsumerId(this.consumer.id.toString()).subscribe({
            next: () => {
              this.router.navigateByUrl('/parrain/compte/personnalisation#creation').then();
            }
        });
    }

    private showErrors() {
        for (const control in this.consumerForm.controls) {
            if (this.consumerForm.get(control).invalid) this.consumerForm.get(control).markAsDirty();
        }
        // Got focus to the error field
        const invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        invalidFields[0].focus();
    }
}
