<div class="container my-2 py-2 py-xl-5">
    <h1>Politique relative aux Cookies</h1>
    <br>
    <p>
        Lorsque vous naviguez sur le site {{ url }} (ci-après le « Site »), {{ clientName }} par l'intermédiaire de
        l'éditeur du site (Isifid) dépose des cookies lui permettant d'améliorer le fonctionnement du site.
    </p>
    <br><br>
    <h3>1. Qu'est-ce qu'un cookie ?</h3>
    <p>
        Un « cookie » est un fichier texte déposé sur votre disque dur par le serveur du site que vous visitez.
        <br><br>
        Il contient généralement le nom du serveur qui l’a déposé, un identifiant sous forme de numéro unique et une
        date
        d’expiration. Il a pour but de collecter des informations relatives à votre navigation afin de vous proposer des
        services adaptés à votre terminal (ordinateur, mobile, ou tablette).
    </p>
    <h3>2. Quels sont les différents types de cookies que nous utilisons et pour quelles finalités ?</h3>
    <p>
        Le type de cookies utilisés sur notre Site est le suivant :
    </p>
    <ul>
        <li>
            • Cookies de mesure d’audience : il s’agit de cookies internes qui peuvent être de session ou persistants.
            Ils
            collectent des informations sur la façon dont vous utilisez et naviguez sur notre Site. À titre d'exemple,
            ces
            cookies garderont en mémoire les pages les plus consultées, le type de navigateur, le fournisseur d'accès
            Internet
            (FAI), les pages de renvoi/sortie, le système d'exploitation, l'horodatage et le parcours de navigation.
            Nous
            utilisons ces informations pour analyser les tendances, gérer le Site et suivre le parcours de navigation
            des
            utilisateurs. Ces cookies nous aident à améliorer la manière dont le Site fonctionne.
        </li>
    </ul>

    <p>En conformité avec les recommandations de la CNIL, ces cookies :</p>
    <ul>
        <li>- sont limités à la seule finalité de mesure d'audience</li>
        <li>- ne permettent pas de recouper les données traitées avec d'autres traitements (fichiers clients,
            statistiques
            de
            fréquentation d'autres sites...)
        </li>
        <li>- ne permettent un tracking que sur ce seul site</li>
        <li>- ont été conçus de manière à ce que les 2 derniers octets de l'adresse IP (internet protocol) soient tronqués
        <li>- ne seront pas conservés au delà d'une durée de 13 mois</li>
    </ul>

    <h3>3. Comment paramétrer le dépôt des cookies ?</h3>
    <p>
        Vous pouvez à tout moment refuser le dépôt de ces cookies et / ou modifier votre choix grâce à la case
        ci-dessous :
    </p>
    <p>
        @if (!isTracked) {
            <input (click)="track()" id="track" type="checkbox"> Vous n'êtes actuellement pas suivi(e). Cochez cette case
            pour participer à l'amélioration du site
        } @else {
            <input (click)="untrack()" checked="checked" id="untrack" type="checkbox"> Vous êtes actuellement suivi(e).
                    Cochez cette case pour ne plus participer à l'amélioration du site.
        }
    </p>

    <h3>4. Pendant combien de temps les cookies seront-ils conservés ?</h3>
    <p>
        Lorsque des cookies ou autres traceurs non-essentiels au fonctionnement du Site sont déposés sur votre terminal,
        ils sont conservés pour une durée maximale de 6 mois (à l’exception des cookies de mesure d’audience qui sont
        conservés 13 mois).
    </p>
</div>
