import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {SponsorService} from '../../../../shared/services/sponsor.service';
import {CustomValidator} from '../../../../shared/helpers/custom.validator';
import {environment} from '../../../../../environments/environment';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TrackingServiceSponsorship} from '../../../../shared/services/tracking-service-sponsorship.service';
import {
    CacheService, ConfigurationService,
    Consumer,
    FormValidators,
    MobileService,
    SponsorshipSettings,
    SponsorshipUser
} from '@isifid/core';

@Component({
    selector: 'app-sponsor-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss']
})
export class CreateComponent {
    sponsorForm: FormGroup;
    legalNoticeSpecificStep1: SafeHtml;
    loading: boolean;
    maxDate: Date;
    minDate: Date;
    settings: SponsorshipSettings;
    private sponsorshipUser: SponsorshipUser;
    private consumer: Consumer;
    private minAge = 18;

    constructor(
        public readonly sponsorshipService: SponsorshipService,
        private readonly mobileService: MobileService,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly router: Router,
        private readonly sponsorService: SponsorService,
        private readonly configurationService: ConfigurationService,
        private readonly sanitizer: DomSanitizer,
        private readonly trackingService: TrackingServiceSponsorship,
        private readonly cacheService: CacheService
    ) {
        this.settings = sponsorshipService.getSettings();
        this.loading = false;
        this.legalNoticeSpecificStep1 = this.sanitizer.bypassSecurityTrustHtml(this.sponsorshipService.getContent().legalNoticeSpecificStep1);
        if (this.settings.sponsorAgeMin) this.minAge = this.settings.sponsorAgeMin;
        this.minDate = this.sponsorshipService.getMinDate();
        this.maxDate = this.sponsorshipService.getMaxDate();
        this.initForm();
    }

    initForm(): void {
        this.sponsorForm = this.formBuilder.group({
            firstName: ['', [Validators.required, CustomValidator.firstNameLastName]],
            lastName: ['', [Validators.required, CustomValidator.firstNameLastName]],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', [Validators.required, this.formValidators.isFrenchMobilePhone]],
            allowCommunications: [this.settings.optOutEnabled]
        });

        if (!!this.settings.sponsorAgeMax || !!this.settings.sponsorAgeMin) this.sponsorForm.addControl('checkAge', new FormControl(false, Validators.requiredTrue));

        if (this.settings.professionalEnabled) this.sponsorForm.addControl('type', new FormControl(false));

        // Ask for advisor email if coming from a flyer
        if (this.trackingService.getUtmParam('utm_medium') === 'flyer' &&
            this.sponsorshipService.getContent().advisorEmailExample !== '') {
            let advisorEmailExample = this.sponsorshipService.getContent().advisorEmailExample.split('@')[1];
            if (!advisorEmailExample) advisorEmailExample = '';
            else advisorEmailExample = '@' + advisorEmailExample;

            this.sponsorForm.addControl('advisorEmail', new FormControl(advisorEmailExample));
        }
    }

    submit(): void {
        if (this.sponsorForm.invalid) {
            this.showErrors();
            return;
        }
        this.loading = true;
        this.populateSponsor();

        // Create a new sponsor
        this.sponsorService.create(this.consumer, this.sponsorshipUser).subscribe({
            next: (sponsor) => {
                if (sponsor) {
                    this.sponsorshipService.setMode('sponsor');
                    this.router.navigateByUrl('/parrain/compte/personnalisation#creation').then();
                } else {
                    // If the sponsor already exists, we redirect him to the login page
                    this.router.navigateByUrl('/parrain/login#recover').then();
                }
            }, error: () => {
                console.error('error while creating sponsor');
                this.router.navigate(['/error']).then();
            }
        });
    }

    private populateSponsor() {
        this.consumer = new Consumer();
        this.consumer.firstName = this.sponsorForm.get('firstName').value;
        this.consumer.lastName = this.sponsorForm.get('lastName').value;
        this.consumer.email = this.sponsorForm.get('email').value;
        this.consumer.mobile = this.mobileService.formatMobile(this.sponsorForm.get('mobile').value);
        // Add giftUser id if coming from giftUser qrcode
        this.consumer.giftUserId = Number(this.cacheService.getContent('giftUserId'));
        // Add branchCode if coming from giftUser qrcode
        this.consumer.branchCode = this.cacheService.getContent('branchCode');
        // If the sponsor is a pro, we need to update the consumer.configuration with type
        if (this.settings.professionalEnabled && this.sponsorForm.get('type').value === true) {
            this.consumer.configuration = this.configurationService.addValue(null, 'typology', 'pro');
        }

        this.sponsorshipUser = new SponsorshipUser();
        this.sponsorshipUser.settingsId = this.settings.id;
        this.sponsorshipUser.clientId = this.settings.clientId;
        this.sponsorshipUser.allowCommunications = this.sponsorForm.get('allowCommunications').value;
    }

    private showErrors() {
        for (const control in this.sponsorForm.controls) {
            if (this.sponsorForm.get(control).invalid) this.sponsorForm.get(control).markAsDirty();
        }
        // Got focus to the error field
        const invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        invalidFields[0].focus();
    }

    protected readonly environment = environment;
}
