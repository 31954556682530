<header class="container-fluid py-4 mx-0">
    <div class="row d-flex justify-content-around">

        <div class="col">
            <div class="row">
                <a (click)="goToHome()">
                    <img [src]="logoUrl" alt="logo" class="my-1 ms-4 ps-lg-3 logo clickable" height="" width="">
                </a>
            </div>
        </div>

        <div class="d-none d-md-block col my-auto ms-auto text-end pt-md-3 mt-md-3">
            @if (settings && sponsorshipUser && !sponsorCodeUrl && links) {
                <span class="ms-3 ms-md-5 my-auto">
                    <a [fragment]="'open'" class="hover-custom text-secondary" routerLink="/parrain/inviter">
                      Mon compte Parrainage
                    </a>
                </span>
            }
        </div>
    </div>
</header>
