import {ErrorHandler, LOCALE_ID, NgModule, APP_ID} from '@angular/core';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routes';
import {SharedModule} from './shared/shared.module';
import {SponsoredModule} from './modules/sponsored/sponsored.module';
import {environment} from '../environments/environment';
import * as Sentry from '@sentry/angular';
import {PublicModule} from './modules/public/public.module';
import {SponsorModule} from './modules/sponsor/sponsor.module';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {CoreModule, HttpJwtInterceptor, LocalErrorHandler, MobileService} from '@isifid/core';

import localeFr from '@angular/common/locales/fr';
import {BootstrapService} from './shared/services/bootstrap.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';

registerLocaleData(localeFr, 'fr');

Sentry.init({
    dsn: environment.sentryUrl,
    integrations: [],
    ignoreErrors: [
        'Non-Error exception captured'
    ],
    beforeSend(event): any {
        // Update the environment
        if (environment.production) event.environment = 'production';
        else if (window.location.origin.startsWith('http://localhost:')) return null;
        else if (environment.apiUrl.indexOf('.dev.') !== -1) event.environment = 'development';
        else event.environment = 'staging';
        event.release = environment.version;
        return event;
    }
});

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        CommonModule,
        SponsorModule,
        PublicModule,
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatExpansionModule,
        MatRadioModule,
        SponsoredModule,
        BrowserAnimationsModule,
        CoreModule.forRoot(environment),
        // Must be the last to catch 404 errors
        AppRoutingModule
    ],
    providers: [
        DatePipe,
        BootstrapService,
        MobileService,
        {
            provide: APP_ID,
            useValue: 'sponsorship'
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpJwtInterceptor, multi: true },
        { provide: ErrorHandler, useClass: LocalErrorHandler },
        { provide: LOCALE_ID, useValue: 'fr' },
        provideHttpClient(withFetch()),
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule {
}
