import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {SponsorshipService} from './sponsorship.service';
import {isPlatformBrowser} from '@angular/common';
import {CacheService, CookiesService} from '@isifid/core';

declare let _paq: any;

@Injectable()
export class TrackingServiceSponsorship {
    constructor(
        private readonly sponsorshipService: SponsorshipService,
        private readonly cacheService: CacheService,
        private readonly cookiesService: CookiesService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
    }

    getClientTrackerId() {
        // Only for real browser
        if (!isPlatformBrowser(this.platformId)) return null;

        if (this.sponsorshipService.getSettings() == null) return null;

        const matomoSiteId = this.sponsorshipService.getSettings().matomoSiteId;
        if (!matomoSiteId) return null;

        return matomoSiteId;
    }

    isTracked(): boolean {
        // Only for real browser
        if (!isPlatformBrowser(this.platformId)) return false;

        return !this.cookiesService.getCookie('mtm_consent_removed');
    }

    stopTracking() {
        // Check _paq to avoid error occurs when building for ssr production
        if (typeof _paq !== 'undefined') {
            _paq.push(['optUserOut']);
        }
    }

    startTracking() {
        // Check _paq to avoid error occurs when building for ssr production
        if (typeof _paq !== 'undefined') {
            _paq.push(['forgetUserOptOut']);
        }
    }

    getUtmParam(paramName: string): string {
        const utms = this.cacheService.getContent('utms');
        if (!utms) return;
        const utmsArray = utms.split('&');
        for (const utm of utmsArray) {
            if (utm.indexOf(paramName) === 0) return utm.split('=')[1];
        }
        return null;
    }

    storeUtmParams(params): void {
        params.subscribe(params => {
            let utm = '';
            for (const param in params) {
                if (param.startsWith('utm') && params[param]) utm += param + '=' + params[param] + '&';
            }
            // Only store if vars were passed and remove trailing &
            if (utm) this.cacheService.addPermanentContent('utms', utm.substring(0, utm.length - 1));
        });
    }
}
