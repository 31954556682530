<app-header />
<div class="col-12 m-0 py-sm-3 py-md-5 pb-sm-5 px-0 px-xl-5 d-flex flex-row justify-content-center">
    <div class="col-12 col-sm-9 col-md-10 col-lg-7 col-xl-6 px-0 pt-3 pb-0 text-center h-100 px-0 me-lg-4">
        <div class="step col mx-auto p-0">

            <div class="row m-0 g-0 py-4 bg-custom text-white loginSponsor">
                <div class="col m-0">
                    <h2 class="h2">Déjà parrain ?</h2>
                    <a class="text-white" routerLink="/parrain/login">Se connecter</a>
                </div>
            </div>

            <div class="row px-5">
                <div class="col px-md-5 text-center">
                    <h1 class="mt-5 h2 custom-text">S’inscrire comme parrain</h1>
                </div>
            </div>

            <div class="row px-5">
                <div class="col px-md-5 text-center">
                    <p class="text-secondary h6">{{sponsorshipService.getContent().sponsorFormText}}</p>
                </div>
            </div>

            <form [formGroup]="sponsorForm" class="my-5 px-5">

                <div class="row">
                    <div class="col">
                        <mat-form-field>
                            <mat-label>Prénom</mat-label>
                            <label for="firstName"></label>
                            <input formControlName="firstName"
                                   id="firstName"
                                   matInput
                                   maxlength="226"
                                   placeholder="Prénom *"
                                   type="text">
                            <em aria-hidden="true" class="fas fa-id-card me-2 text-secondary" matPrefix></em>
                        </mat-form-field>
                        <app-form-errors [control]="sponsorForm.controls.firstName" class="mt-0" />
                    </div>
                </div>

                <div class="row">
                    <div class="col mx-auto">
                        <mat-form-field>
                            <mat-label>Nom</mat-label>
                            <input formControlName="lastName" id="lastName" matInput
                                   placeholder="Nom"
                                   type="text">
                            <em aria-hidden="true" class="fas fa-id-card me-2 text-secondary" matPrefix></em>
                        </mat-form-field>
                        <app-form-errors [control]="sponsorForm.controls.lastName" class="mt-0" />
                    </div>
                </div>

                <div class="row">
                    <div class="col mx-auto">
                        <mat-form-field>
                            <mat-label>Téléphone</mat-label>
                            <input formControlName="mobile" id="mobile" matInput
                                   placeholder="Telephone"
                                   type="tel">
                            <em aria-hidden="true" class="fas fa-phone me-2 text-secondary" matPrefix></em>
                        </mat-form-field>
                        <app-form-errors [control]="sponsorForm.controls.mobile" class="mt-0" />
                    </div>
                </div>

                <div class="row">
                    <div class="col mx-auto">
                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input formControlName="email" id="email" matInput placeholder="Email"
                                   type="email">
                            <em aria-hidden="true" class="fas fa-envelope me-2 text-secondary" matPrefix></em>
                        </mat-form-field>
                        <app-form-errors [control]="sponsorForm.controls.email" class="mt-0" />
                    </div>
                </div>

                @if (!!settings.sponsorAgeMin || !!settings.sponsorAgeMax) {
                    <div class="row  my-3">
                        <div class="col px-3">
                            <mat-checkbox color="primary" formControlName="checkAge" id="checkAge">
                                <mat-label class="px-2 text-medium clickable text-wrap">
                                    @if (settings.sponsorAgeMax < 30) {
                                       <span>Je confirme avoir entre
                                           {{ settings.sponsorAgeMin || 18 }} et
                                           {{ settings.sponsorAgeMax || 30 }} ans.
                                        </span>
                                    } @else if (settings.sponsorAgeMax > 30) {
                                        <span>
                                            Je confirme avoir plus de {{ settings.sponsorAgeMin || 18 }} ans.
                                        </span>
                                    }
                                </mat-label>
                            </mat-checkbox>
                            @if (sponsorForm.get('checkAge').invalid && sponsorForm.touched) {
                                <mat-error>
                                    Merci de confirmer votre âge.
                                </mat-error>
                            }
                        </div>
                    </div>
                }

                <!-- professionalEnabled --------------------------------------------------------------------------- -->
                @if (settings.professionalEnabled) {
                    <div class="row mt-3 mb-4">
                        <div class="col">
                            <mat-checkbox class="text-wrap my-2"
                                          color="primary"
                                          formControlName="type"
                                          id="isPro">
                                <mat-label class="px-2 clickable text-wrap">Je suis un client professionnel</mat-label>
                            </mat-checkbox>
                        </div>
                    </div>
                }

                <!-- advisorEmail flyer ---------------------------------------------------------------------------- -->
                @if (sponsorForm.contains('advisorEmail')) {
                    <div class="row mt-3 mb-4">
                        <div class="col">
                            <label for="advisorEmail">Adresse email de votre conseiller</label>
                            <input formControlName="advisorEmail"
                                   id="advisorEmail"
                                   maxlength="226"
                                   placeholder=""
                                   type="text">
                            <em aria-hidden="true" class="fas fa-id-card me-2 text-secondary" matPrefix></em>
                            <mat-hint>
                                Exemple d'adresse email : {{sponsorshipService.getContent().advisorEmailExample}}
                            </mat-hint>
                            <app-form-errors [control]="sponsorForm.controls.advisorEmail" class="mt-1" />
                        </div>
                    </div>
                }

                <div class="row mt-4">
                    <div class="col px-3">
                        @if (sponsorshipService.settings.templateHash !== 'CADS9488E2A7BB9515D') {
                            <p class="mb-3">
                                Votre enregistrement sur la plateforme de parrainage vous permettra d’être régulièrement
                                tenu informé(e) par email des nouvelles récompenses, résultats de vos actions et
                                moyens de parrainer sans effort.
                            </p>
                        }
                        <mat-checkbox color="primary" formControlName="allowCommunications">
                            <mat-label class="px-2 text-medium clickable text-wrap">
                                Je souhaite recevoir les nouveautés relatives au programme de parrainage.
                            </mat-label>
                        </mat-checkbox>
                    </div>
                </div>

                @if (settings.templateHash === 'CECAZ05FC8A441CBCF53') {
                    <div class="text-medium mt-2 px-md-2">
                        La Caisse d’Epargne Côte d’Azur recueille en tant que responsable de traitement des données à
                        caractère personnel vous concernant.
                        Les informations vous expliquant pourquoi et comment la Caisse d’Epargne Côte d’Azur utilisera vos
                        données, combien de temps elles seront conservées ainsi que les droits dont vous disposez s’agissant
                        de ces données figurent dans notre notice d’information. Vous pouvez y accéder ici et à tout moment
                        sur notre site internet <a href="https://www.caisse-epargne.fr/cote-d-azur" target="_blank">
                        https://www.caisse-epargne.fr/cote-d-azur</a> ou sur simple demande auprès de votre agence.
                    </div>
                }

                <div class="row mt-4">
                    <div class="col">
                        @if (!loading) {
                            <button (click)="submit()"
                                    class="btn btn-primary mt-2 p-3 text-nowrap"
                                    data-cy="submit"
                                    name="button">
                                S’enregistrer
                            </button>
                        } @else {
                            <button class="btn btn-primary mt-2 px-4 py-2" disabled>
                                <em aria-hidden="true" class="fas fa-spinner fa-spin"></em>
                            </button>
                        }
                    </div>
                </div>

                <div class="row my-2">
                    <div class="col">
                        <p class="text-small p-3">
                            Les données collectées via ce formulaire sont utilisées par votre banque pour vous permettre
                            de parrainer vos amis
                            @if (settings.templateHash !== 'CEBFCD8CA8004FA96241') {
                                <span>et vous envoyer, le cas échéant, des informations susceptibles de vous intéresser</span>.
                            }
                        </p>
                    </div>
                </div>

            </form>

        </div>
    </div>

    <div class="d-none d-lg-flex flex-column card h-50 mt-3 pt-2">
        <div class="row mx-auto">
            <div class="col mb-1">
                <img [src]="environment.cdnUrl + '/clients/' + settings.templateHash + '/logo.png'"
                     alt="logo" class="logo" height="" width="">
            </div>
        </div>
        <div class="row m-0 p-0">
            <div class="col mt-3 m-0 p-0">
                <img [src]="environment.cdnUrl + '/clients/' + settings.templateHash + '/banner.jpg'"
                     alt="banner" class="banner" height="" width="">
            </div>
        </div>
    </div>
</div>

@if (legalNoticeSpecificStep1) {
    <section class="mt-5">
        <div class="col-xs-11 m-5">
            <p [innerHTML]="legalNoticeSpecificStep1" class="text-justify px-1 legal-notice"></p>
        </div>
    </section>
}

<app-footer />
