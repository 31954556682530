<section>
    <app-header />

    @if (sponsorshipService.getContent() && sponsorshipService.getSettings().sponsoredAmount > 0) {
        <div class="custom-text2 col mx-auto mt-3 text-center">

            <div class="row g-0">
                <div class="col col-md-10 col-lg-9 col-x-8 mx-auto">
                    <div [innerHTML]="sponsorshipService.getContent().sponsorCodeHashtag"
                        class="h2 text-secondary my-3 px-md-5 fs-2"></div>
                    <div [innerHTML]="content.sponsorCode1" class="mt-1 text-secondary"></div>
                </div>
            </div>

            <div class="container my-3 mx-auto border shadow">
                <div class="row">
                    @if (!sponsorshipService.rewardWithCash) {

                        <div class="col-12 col-md-6 m-md-4 order-2 order-md-1">
                            <div class="d-flex carousel slide" data-bs-ride="carousel" id="slidesCarousel">

                                <!-- Indicators/dots -->
                                <div class="carousel-indicators w-100 mt-5">
                                    @for (s of slides; track s; let index = $index) {
                                        <button [attr.data-bs-slide-to]="index"
                                                [class.active]="index === 0"
                                                data-bs-target="#slidesCarousel" type="button"> </button>
                                    }
                                </div>

                                <div class="carousel-inner w-100">
                                    @for (slide of slides; track slide + i; let i = $index) {
                                        <div [class.active]="i === 0"
                                             class="carousel-item">
                                            <img [src]="slide" alt="Slide {{i}}" height="" width="100%"/>
                                        </div>
                                    }
                                </div>

                                <!-- Left and right controls/icons -->
                                <button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#slidesCarousel"
                                        type="button">
                                    <span class="carousel-control-prev-icon"></span>
                                </button>
                                <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#slidesCarousel"
                                        type="button">
                                    <span class="carousel-control-next-icon"></span>
                                </button>

                            </div>
                        </div>
                    }
                    <div class="col order-1 order-md-2 d-md-flex flex-md-column">
                        @if (!sponsorshipService.rewardWithCash) {
                            <img alt="bon d'achat" class="w-100 mx-0 mt-4 d-block align-self-start" height="auto"
                                 src="{{cdnUrl}}/sponsorship/img/vouchers/encart_{{sponsorshipService.getSettings().sponsorAmount}}.jpg"
                                 width="100%">
                        }
                        <div class="align-self-center px-md-5 text-center">
                            <button (click)="goToAppointment()" [class.mt-5]="sponsorshipService.rewardWithCash"
                                    class="btn btn-code mt-4 mb-3 px-4 py-3"
                                    data-cy="button">
                                @if (sponsorshipService.getSettings().templateHash === 'CECAZ05FC8A441CBCF53') {
                                    <span class="h6">Je prends rendez-vous</span>
                                } @else {
                                    <span class="h6">J’en profite</span>
                                }
                            </button>
                        </div>
                        <p [innerHTML]="sponsorshipService.getContent().sponsorCode2"
                           class="text-secondary mt-lg-3 mt-xl-5"></p>
                        @if (!sponsorshipService.getSettings().appointmentEmailMethod) {
                            <div class="justify-content-center mt-1">
                                <p class="h6 w-100">Mon code Parrain</p>
                                <p class="h6 font-weight-bold">{{ this.sponsorshipUser.codeCustomised || this.sponsorshipUser.code }}</p>
                                <button (cdkCopyToClipboardCopied)="buttonCopyContent='Copié !'"
                                        [cdkCopyToClipboard]="shareUrl"
                                        [innerHTML]="buttonCopyContent"
                                        class="btn btn-secondary px-3 py-1 my-2" type="button">
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    @if (
        sponsorshipService.getContent() &&
        (sponsorshipService.getSettings().sponsoredAmount === 0 ||
        !sponsorshipService.getSettings().sponsoredAmount)
    ) {
        <div class="col col-md-11 mx-auto my-5 pb-5 text-center noRewardSponsored">
            <p class="my-3">Rendez-vous en agence muni de votre code parrain.</p>
            <div [style.background-image]="backgroundImgNoSponsoredReward()"
                 class="bg-img mx-auto text-white d-flex flex-column justify-content-end mat-elevation-z2">
                <div class="row my-3">
                    <button (click)="goToAppointment()"
                            class="btn btn-transparent px-3 py-3 col-7 col-md-6 col-lg-4 mx-auto fw-bold"
                            type="button">
                        Je prends rendez-vous
                    </button>
                </div>
                <div class="row my-3">
                    <p class="px-5">Pour prendre rendez-vous et ouvrir votre premier compte chez nous,<br>
                        veuillez cliquer ci-dessus.</p>
                </div>
                <div class="row my-3">
                    <p (cdkCopyToClipboardCopied)="spanCopy = 'code copié'"
                       [cdkCopyToClipboard]="shareUrl"
                       class="px-5">Mon code Parrain :
                        <span class="fw-bold">{{ this.sponsorshipUser.codeCustomised || this.sponsorshipUser.code }}</span>
                        @if (!spanCopy) {<i aria-hidden="true" class="far fa-copy ms-2"></i>}
                        @else {<i aria-hidden="true" class="fas fa-copy ms-2"></i>}
                        <br><span [innerHTML]="spanCopy"> </span>
                    </p>
                </div>
            </div>
        </div>
    }

    @if (legalNoticeSpecificStep3) {
        <section class="mt-5">
            <div class="col-xs-11 m-5">
                <p [innerHTML]="legalNoticeSpecificStep3" class="text-justify px-1 legal-notice"></p>
            </div>
        </section>
    }

    <app-footer />
</section>
