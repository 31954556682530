import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {publicRouting} from './public.routes';
import {CookiesComponent} from './cookies/cookies.component';
import {ErrorComponent} from './error/error.component';
import {SessionHasExpiredComponent} from './session-has-expired/session-has-expired.component';
import {SharedModule} from '../../shared/shared.module';
import {HomeComponent} from '../sponsored/home/home.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SponsorModule} from '../sponsor/sponsor.module';
import {AuthComponent} from './auth/auth.component';
import {LogoutComponent} from './logout/logout.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {AutologinComponent} from './autologin/autologin.component';

@NgModule({
    imports: [
        CommonModule,
        publicRouting,
        SharedModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        SponsorModule,
        ClipboardModule
    ],
    declarations: [
        AuthComponent,
        AutologinComponent,
        CookiesComponent,
        ErrorComponent,
        HomeComponent,
        LogoutComponent,
        SessionHasExpiredComponent
    ],
    exports: [
        CookiesComponent,
        ErrorComponent,
        HomeComponent,
        SessionHasExpiredComponent
    ]
})
export class PublicModule {
}
