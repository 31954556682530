<div class="container-fluid m-0 p-0">

    <app-header />
    <div class="explanation">

        <div class="m-0 mx-auto py-2 px-5 ms-xl-5">
            <h1 class="d-none d-lg-block h5 fw-bold explanation py-4 pb-3 ps-2 text-start">
                {{ content.explanationsTitle1 | uppercase }}
            </h1>
            <p class="d-block d-lg-none fw-bold custom-text py-4 m-0 px-0 text-center">
                {{ content.explanationsTitle1 | uppercase }}
            </p>
        </div>

        <section [style.background-image]="explanationsBackgroundImageMobile()"
                 class="bg-custom text-center bg-img-sm">
            <div class="px-0 ps-xl-5 mx-auto col-12 d-flex flex-row justify-content-between text-white">
                <div class="py-4 px-3 mx-auto d-flex flex-column justify-content-around">

                    <div [innerHTML]="content.explanationsTitle2"
                        class="d-none d-lg-block h4 my-3 px-5 fw-lighter fs-2"></div>
                    <div [innerHTML]="content.explanationsTitle2"
                        class="d-block d-lg-none h5 my-3 px-3 fw-lighter fs-2"></div>

                    <div [innerHTML]="content.explanations1"
                        class="h4 d-none d-lg-block align-bottom my-1 my-lg-3 px-3 fw-lighter fs-2"></div>
                    <div [innerHTML]="content.explanations1"
                        class="h5 d-block d-lg-none align-bottom my-1 my-lg-3 px-3 fw-lighter fs-2"></div>

                    <div [innerHTML]="content.explanations2"
                         class="h5 d-none d-md-flex justify-content-center mt-4 laurel"></div>
                    <div [innerHTML]="content.explanations2"
                         class="h4 d-block d-md-none justify-content-center mt-4 laurel"></div>

                    <p [innerHTML]="content.explanations3" class="fw-lighter"></p>

                    <p class="align-bottom  mt-4">
                        @if (content.sponsorshipButton) {
                            <button (click)="referralCreation()"
                                    class="d-block mx-auto btn btn-secondary p-3"
                                    data-cy="sponsorshipButton"
                                    name="button"
                                    type="button">
                                {{content.sponsorshipButton}}
                            </button>
                        }
                    </p>

                </div>
                <div [style.background-image]="explanationsBackgroundImage()"
                     class="d-none d-md-block bg-img p-0"></div>
            </div>
        </section>

        <section class="m-auto px-md-4 d-none d-md-block col-md-12">

            <h2 class="h1 ps-3 my-5 custom-text text-center">{{content.explanationsTitle3}}</h2>

            <div class="d-flex flex-row justify-content-around custom-text">
                <div class="mx-3 px-1 col">
                    <div [style.background-image]="advantagesBackgroundImage('0-welcome.jpg')"
                         class="img-box mb-3"></div>
                    <div [innerHTML]="explanationsAdvantages1" class="px-1"></div>
                </div>
                <div class="mx-3 px-1 col">
                    <div [style.background-image]="advantagesBackgroundImage('1-benefits.jpg')"
                         class="img-box mb-3"></div>
                    <div [innerHTML]="explanationsAdvantages2" class="px-1"></div>
                </div>
                <div class="mx-3 px-1 col">
                    <div [style.background-image]="advantagesBackgroundImage('2-gifts.jpg')"
                         class="img-box mb-3"></div>
                    <div [innerHTML]="explanationsAdvantages3" class="px-1"></div>
                </div>
                @if (content.explanationsAdvantages4) {
                    <div class="mx-3 px-1 col">
                        <div [style.background-image]="advantagesBackgroundImage('3-online.jpg')"
                             class="img-box mb-3"></div>
                        <div [innerHTML]="explanationsAdvantages4" class="px-1"></div>
                    </div>
                }
            </div>

            <div class="text-center m-5">
                @if (content.sponsorshipButton) {
                    <button (click)="referralCreation()"
                            class="btn btn-primary p-3"
                            name="button"
                            type="button">
                        {{content.sponsorshipButton}}
                    </button>
                }
            </div>

        </section>

        <section class="d-block d-md-none col-12 m-0 p-0">
            <div class="d-flex carousel slide" data-bs-ride="carousel" id="slidesCarousel">

                <!-- Indicators/dots -->
                <div class="carousel-indicators w-100 mt-5">
                    @for (s of slides; track index; let index = $index) {
                        <button [attr.data-bs-slide-to]="index"
                                [class.active]="index === 0"
                                data-bs-target="#slidesCarousel" type="button"> </button>
                    }
                </div>

                <div class="carousel-inner w-100">
                    @for (slide of slides; track slide.imgUrl; let i = $index) {
                        <div [class.active]="i === 0" class="carousel-item">
                            <img [src]="slide.imgUrl" alt="Slide {{i}}" height="" width="100%"/>
                            <div class="carousel-title w-100 px-2 text-center text-secondary">
                                <span [innerHTML]="slide.explanationsAdvantages"></span>
                            </div>
                        </div>
                    }
                </div>

                <!-- Left and right controls/icons -->
                <button class="carousel-control-prev" data-bs-slide="prev" data-bs-target="#slidesCarousel"
                        type="button">
                    <span class="carousel-control-prev-icon"></span>
                </button>
                <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#slidesCarousel"
                        type="button">
                    <span class="carousel-control-next-icon"></span>
                </button>

            </div>
        </section>

        <section class="bg-light px-md-4 pb-5">
            <div class="m-0 p-0 col d-flex flex-column">
                <h2 class="d-none d-md-block h2 my-5 custom-text2 text-center">{{ content.explanationsTitle4 }}</h2>
                <h1 class="d-md-none h1 my-5 custom-text2 text-center">{{ content.explanationsTitle4 }}</h1>

                <div class="d-flex flex-column flex-lg-row justify-content-around px-3 px-md-0 ps-md-5">
                    <div class="col mx-auto mx-lg-2">
                        <div class="d-flex flex-row mx-auto">
                            <div class="h2 me-2 bg-white text-center numberCircle pt-2 pt-lg-1">1</div>
                            <p [innerHTML]="content.explanationsHowitworksTitle1"
                               class="h4 fw-bolder pt-2 custom-text2"></p>
                        </div>
                        <div class="mx-auto">
                            <p [innerHTML]="content.explanationsHowitworks1"
                               class="text-secondary pt-3 ps-5 px-sm-5"></p>
                        </div>
                    </div>
                    <div class="col mx-auto mx-lg-2">
                        <div class="d-flex flex-row mx-auto">
                            <div class="h2 me-2 bg-white text-center numberCircle pt-2 pt-lg-1">2</div>
                            <p [innerHTML]="content.explanationsHowitworksTitle2"
                               class="h4 fw-bolder pt-2 custom-text2"></p>
                        </div>
                        <div class="mx-auto">
                            <p [innerHTML]="content.explanationsHowitworks2"
                               class="text-secondary pt-3 ps-5 px-sm-5"></p>
                        </div>
                    </div>
                    <div class="col mx-auto mx-lg-2">
                        <div class="d-flex flex-row mx-auto">
                            <div class="h2 me-2 bg-white text-center numberCircle pt-2 pt-lg-1">3</div>
                            <p class="h4 fw-bolder pt-2 custom-text2">{{ content.explanationsHowitworksTitle3 }}</p>
                        </div>
                        <div class="mx-auto">
                            <p [innerHTML]="content.explanationsHowitworks3"
                               class="text-secondary pt-3 ps-5 px-sm-5"></p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    </div>

    <section class="explanation text-center py-5">
        <h2 class="h1 mb-3 custom-text">{{ content.explanationsTitle5 }}</h2>
        @if (content.sponsorshipButton) {
            <button (click)="referralCreation()"
                    class="btn btn-primary p-3 mb-3"
                    name="button">
                {{content.sponsorshipButton}}
            </button>
        }
    </section>

    @if (legalHomepage) {
        <section class="text-center mt-5">
            <div class="col-xs-11 m-5">
                <p [innerHTML]="legalHomepage" class="text-justify px-1 legal-notice"></p>
            </div>
        </section>
    }

    <app-footer />
</div>
