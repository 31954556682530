<app-header />

<div class="col-12 m-0 py-sm-3 py-md-5 pb-sm-5 px-0 px-xl-5 d-flex flex-row justify-content-center">

    <div class="col col-sm-9 col-md-10 col-lg-7 col-xl-6 px-0 pt-3 pb-0 text-center h-100 me-lg-4">
        <div class="step col mx-auto p-0">

            <div class="row g-0">
                <div class="col col-md-9 mx-md-auto px-5">
                    <h2 class="h2 custom-text text-start pt-4">Se connecter</h2>
                </div>
            </div>

            <div class="row g-0 mt-3 pt-4 pb-3">
                <div class="col col-md-9 mx-md-auto px-4">
                    <p class="text-secondary">
                        @if (fragment === 'recover') {
                            Vous êtes déjà inscrit !<br>
                            Un email de connexion vient de vous être envoyé.
                        } @else {
                            Afin d’accéder à votre compte parrainage, veuillez cliquer sur le lien de connexion qui vous a
                            été envoyé par email lors de votre inscription. Pour recevoir de nouveau l’email de connexion,
                            veuillez indiquer votre adresse email.

                            @if (sent && !notFound) {
                                <span>
                                    Email de connexion envoyé à {{resendLoginForm.value.email}}
                                </span>
                            } @else if (notFound) {
                                <span>Adresse email inconnue</span>
                            }
                        }
                    </p>
                </div>
            </div>

            @if (fragment !== 'recover') {

                <div class="g-0 row">
                    <div class="col col-md-9 mx-md-auto px-5">
                        <form (ngSubmit)="submit()" [formGroup]="resendLoginForm">
                            <div class="d-flex mx-auto">
                                <label class="d-none" for="emailAlready">Adresse e-mail</label>
                                @if (!sent) {
                                    <mat-form-field>
                                        <mat-label>Email</mat-label>
                                        <input formControlName="email" id="email" matInput placeholder="Email"
                                                type="email">
                                        <em aria-hidden="true" class="fas fa-envelope me-2 text-secondary" matPrefix></em>
                                    </mat-form-field>
                                }
                                @if (!loading && !sent) {
                                    <button (click)="submit()" class="btn btn-primary ms-3"
                                            id="submit">Envoyer
                                    </button>
                                } @else if (loading && !sent) {
                                    <button class="btn btn-primary ms-3 text-nowrap" disabled>
                                        <em aria-hidden="true" class="fas fa-spinner fa-spin"></em> Envoi en cours
                                    </button>
                                } @else if (!loading && sent) {
                                    <button class="btn btn-primary mx-auto" disabled>
                                        <em aria-hidden="true" class="fas fa-mail"></em> Envoyé
                                    </button>
                                }
                            </div>
                            <app-form-errors [control]="resendLoginForm.controls.email" class="mt-0" />
                        </form>
                    </div>
                </div>
            }

            <div class="row g-0 mt-4 mx-0 px-0 py-4 bg-custom text-white registerSponsor">
                <div class="col m-0">
                    <h2 class="h2">Retour à l'inscription</h2>
                    <a class="text-white" routerLink="/parrain/compte/creation">S'inscrire</a>
                </div>
            </div>
        </div>
    </div>

    <div class="d-none d-lg-flex flex-column card h-50 mt-3 pt-2">
        <div class="row mx-auto">
            <div class="col mb-1">
                <img [src]="environment.cdnUrl +'/clients/'+ sponsorshipService.getSettings().templateHash +'/logo.png'"
                     alt="logo" class="logo" height="" width="">
            </div>
        </div>
        <div class="row">
            <div class="col mt-3 mx-0 py-0">
                <img [src]="environment.cdnUrl +'/clients/'+ sponsorshipService.getSettings().templateHash +'/banner.jpg'"
                     alt="banner" class="banner p-0 m-0" height="" width="">
            </div>
        </div>
    </div>

</div>

<app-footer />
