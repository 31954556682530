import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AutologinComponent} from './modules/public/autologin/autologin.component';

const routes: Routes = [
    {
        path: '**',
        component: AutologinComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        initialNavigation: 'enabledBlocking'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
