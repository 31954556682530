import {Injectable} from '@angular/core';
import {CacheService} from '@isifid/core';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ShareService {
    webNavigator: any = null;

    constructor(
        private readonly cacheService: CacheService
    ) {
        this.webNavigator = window.navigator;
    }

    storeHasSharedByEmail(): void {
        this.cacheService.addPermanentContent('share_by_email', 'true');
    }

    canShare(): boolean {
        return this.webNavigator?.share !== undefined;
    }

    share({title, text, url}: { title: string, text?: string, url?: string }): Observable<{shared: boolean}> {
        if (this.webNavigator === null || this.webNavigator.share === undefined) return of(null);
        if ((text === undefined || text === null) && (url === undefined || url === null)) {
            console.warn('text and url both can\'t be empty, at least provide either text or url');
            return of(null);
        } else {
            window.open(`mailto:?subject=${title}&body=${url}%0D%0A${text}`);
            return of({shared: true});
        }
    }

    canPickContact() {
        return ('contacts' in navigator && 'ContactsManager' in window);
    }

    contactPick(): Observable<any> {
        if ('contacts' in this.webNavigator && 'select' in this.webNavigator.contacts && 'getProperties' in this.webNavigator.contacts) {
            try {
                const availableProperties = this.webNavigator.contacts.getProperties();

                if (availableProperties.includes('email') && availableProperties.includes('name')) {
                    const contactProperties = ['name', 'email'];
                    return of(this.webNavigator.contacts.select(contactProperties, {multiple: true}));
                } else {
                    console.error('Contact Picker API on your device doesn\'t support email or name property');
                    return of(null);
                }
            } catch {
                console.error('Unexpected error happened in Contact Picker API');
                return of(null);
            }
        }
    }
}
