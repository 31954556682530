<div class="container-fluid m-0 p-0 d-flex flex-column justify-content-between">

    <app-header />

    <div class="col mx-0 my-5 py-5 px-0 px-xl-5">
        <div class="mx-auto col col-sm-8 col-xl-7 px-0 pt-3 pb-5 text-center border shadow">
            <div class="h5 mt-4 px-4 px-xl-5 text-start custom-text2">
                Votre demande a bien été prise en compte, un conseiller clientèle aura le plaisir de vous contacter très
                prochainement.
            </div>
        </div>
    </div>

    <app-footer />

</div>
