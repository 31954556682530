import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SponsorService} from '../../../../shared/services/sponsor.service';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../environments/environment';

@Component({
    selector: 'app-sponsor-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent {
    fragment: string;
    loading = true;
    notFound = false;
    resendLoginForm: FormGroup;
    sent = false;

    constructor(
        public readonly sponsorshipService: SponsorshipService,
        private readonly formBuilder: FormBuilder,
        private readonly route: ActivatedRoute,
        private readonly sponsorService: SponsorService
    ) {
        this.route.fragment.subscribe(fragment => this.fragment = fragment);

        this.resendLoginForm = formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
        this.loading = false;
    }

    submit(): void {
        this.loading = true;
        this.notFound = false;
        if (this.resendLoginForm.invalid) {
            this.loading = false;
            return;
        }
        this.sponsorService.sendAccessByEmail(this.resendLoginForm.get('email').value).subscribe({
            next: () => {
                this.sent = true;
            },
            error: () => {
                console.error('error while sending Access By Email');
                this.notFound = true;
                this.loading = false;
            },
            complete: () => {
                if (!this.sent) this.notFound = true;
                this.loading = false;
                setTimeout(() => this.sent = false, 5000);
            }
        });
    }

    protected readonly environment = environment;
}
