@if (!loading) {
    <section class="container-fluid min-vh-100">
        <h1 class="h1 pt-5 fw-bolder text-center">La page à laquelle vous tentez d’accéder semble ne pas exister.</h1>
        <p class="fixed-bottom text-end text-white m-4">Photo by Daniel Jensen on Unsplash</p>
    </section>
} @else {
    <div class="loading">
        <em aria-hidden="true" class="fas fa-spinner fa-spin"></em><br>
        <span>Chargement...</span>
    </div>
}

