import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {SponsorshipService} from '../services/sponsorship.service';
import {SponsorService} from '../services/sponsor.service';
import {BootstrapService} from '../services/bootstrap.service';

@Injectable({
    providedIn: 'root'
})
export class SponsorshipGuard {
    constructor(
        private sponsorService: SponsorService,
        private sponsorshipService: SponsorshipService,
        private bootstrapService: BootstrapService,
        private router: Router
    ) {
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
        // init data from cache
        this.bootstrapService.initEntitiesFromCache();

        // allow to visit url if the mode is sponsor or advisor and both sponsorshipUser and consumer are exist
        // otherwise redirect to log out page
        if (
            (this.sponsorshipService.mode === 'sponsor' || this.sponsorshipService.mode === 'advisor' || this.sponsorshipService.mode === 'sponsored') &&
            this.sponsorService.sponsorshipUser &&
            this.sponsorService.consumer
        ) {
            return true;
        } else {
            return this.router.navigate(['/logout']);
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
        return this.canActivate(childRoute, state);
    }
}

export const sponsorshipGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(SponsorshipGuard).canActivate(route, state);
};

export const sponsorshipGuardChildFn: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(SponsorshipGuard).canActivateChild(route, state);
};
