@if (loading) {
    <div class="loading">
        <em aria-hidden="true" class="fas fa-spinner fa-spin"></em><br>
        <span>Chargement...</span>
    </div>
} @else {
    <app-header />

    <div class="container-fluid g-0 appointment">
        <div class="row g-0">
            <div class="col col-sm-10 col-md-8 col-xl-7 px-2 px-sm-5 mx-sm-auto my-sm-5 text-center custom-text2 card">

                <form (ngSubmit)="submit()"
                      [formGroup]="appointmentForm"
                      class="px-xl-5 py-2">

                    <div class="mx-auto my-3 text-wrap text-center h6">Pour prendre rendez-vous je valide et complète
                        mes
                        coordonnées
                    </div>

                    <div class="row ps-3 my-4">
                        <mat-radio-group aria-label="gender" color="primary" formControlName="gender">
                            <mat-radio-button class="mx-2" value="2"> Madame</mat-radio-button>
                            <mat-radio-button class="mx-2" value="1"> Monsieur</mat-radio-button>
                        </mat-radio-group>
                        <app-form-errors [control]="appointmentForm.controls.gender" />
                    </div>

                    <div class="row my-2">
                        <div class="col-6 pe-1">
                            <mat-form-field appearance="outline">
                                <mat-label>Prénom</mat-label>
                                <label for="firstName"></label>
                                <input class="form-control"
                                       formControlName="firstName"
                                       id="firstName"
                                       matInput
                                       maxlength="226"
                                       placeholder="Prénom *"
                                       type="text">
                            </mat-form-field>
                            <app-form-errors [control]="appointmentForm.controls.firstName" />

                        </div>

                        <div class="col-6 ps-1">
                            <mat-form-field appearance="outline">
                                <mat-label>Nom</mat-label>
                                <input class="form-control"
                                       formControlName="lastName"
                                       id="lastName"
                                       matInput
                                       placeholder="Nom"
                                       type="text">
                            </mat-form-field>
                            <app-form-errors [control]="appointmentForm.controls.lastName" />
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class="col col-xl-6 mx-auto ps-xl-1">
                            <mat-form-field appearance="outline" class="mailField">
                                <mat-label>Adresse e-mail</mat-label>
                                <input class="form-control"
                                       formControlName="email"
                                       id="email"
                                       matInput
                                       placeholder="Adresse e-mail"
                                       type="email">
                            </mat-form-field>
                            <app-form-errors [control]="appointmentForm.controls.email" />
                        </div>
                    </div>

                    <div class="row my-2">
                        <div class=" pe-1 col-6">
                            <mat-form-field appearance="outline">
                                <mat-label>Téléphone</mat-label>
                                <input class="form-control"
                                       formControlName="mobile"
                                       id="mobile"
                                       matInput
                                       placeholder="Téléphone"
                                       type="tel">
                            </mat-form-field>
                            <app-form-errors [control]="appointmentForm.controls.mobile" />
                        </div>

                        <div class="ps-1 col-6">
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Date de naissance</mat-label>
                                <input [matDatepicker]="picker1"
                                       class="form-control"
                                       id="birthDate"
                                       formControlName="birthDate"
                                       matInput>
                                <mat-datepicker-toggle [for]="picker1" matPrefix></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                            <app-form-errors [control]="appointmentForm.controls.birthDate" />
                        </div>
                    </div>

                    @if (settings.templateHash !== 'CEMPAB93BCA26396A02') {
                        <div class="row">
                            <div class="col-sm-6 pt-4">
                                <label class="text-wrap" for="sponsorAgency">L'agence de mon parrain :</label>
                            </div>
                            <div class="col-sm-6 ps-sm-1">
                                <mat-form-field appearance="outline">
                                    <mat-label>L'agence de mon parrain</mat-label>
                                    <input [value]="agencySelected.nom | titlecase" class="form-control"
                                           formControlName="sponsorAgency"
                                           id="sponsorAgency"
                                           matInput
                                           placeholder="Nom de son agence"
                                           type="text">
                                </mat-form-field>
                                <app-form-errors [control]="appointmentForm.controls.sponsorAgency" />
                            </div>
                        </div>
                        <div class="text-start my-3 ps-2">
                            Merci de nous préciser la plage horaire à laquelle vous souhaitez prendre rendez-vous :
                        </div>
                    } @else {
                        <div class="text-start my-3 ps-2">
                            Merci de nous préciser la plage horaire à laquelle vous souhaitez être rappelé :
                        </div>
                    }

                    <div class="row">
                        <div class="col-sm-6 pt-4">
                            <label for="timeSlots">Plage horaire souhaitée :</label>
                        </div>

                        <div class="col-sm-6 ps-sm-1">
                            <mat-form-field appearance="outline">
                                <mat-label>Plage horaire souhaitée :</mat-label>
                                <mat-select formControlName="preferredTime" id="timeSlots">
                                    @for (timeSlot of timeSlots; track timeSlot) {
                                        <mat-option [value]="timeSlot">
                                            {{ timeSlot }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            <app-form-errors [control]="appointmentForm.controls.preferredTime" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary my-2" data-cy="submit" name="button" type="submit">
                                Valider
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>

        @if (legalNoticeSpecificStep3) {
            <section class="mt-5">
                <div class="col-xs-11 m-5">
                    <p [innerHTML]="legalNoticeSpecificStep3" class="legalNoticeSpecificStep3 text-justify px-1"></p>
                </div>
            </section>
        }

        <app-footer />

    </div>
}

