<app-header />

<app-sponsor-shared-banner />

@if (!loading) {
    <div class="container-fluid custom-text step-2 mt-4 pb-5">
        @if (isAllowedToInvite) {
    
            <div class="col-12 col-md-10 col-xl-8 mx-auto g-0 py-4 px-0 d-flex justify-content-center invite">
                <div class="container-fluid">
                    <div class="row my-3">
                        <div class="col">
                            <div class="h1 text-center">Invitez vos proches</div>
                        </div>
                    </div>
    
                    @if (sponsorshipService.getSettings().socialShareEnabled) {
                        <div class="row">
                            <div class="col">
                                <div class="my-3 text-center bg-custom2 w-100 py-4 text-white h4">
                                    Partagez votre code parrain
                                </div>
                                <div class="text-secondary px-5 mt-5 mb-3">
                                    Simple et redoutablement efficace, le partage sur les réseaux sociaux permet de
                                    communiquer rapidement votre code parrain à une large audience et de maximiser vos
                                    chances d’obtenir des filleuls.
                                </div>
                                <div class="row mt-3 mb-5">
                                    <div class="col-12 col-md d-flex flex-row justify-content-center">
                                        @if (isShareApiAvailable()) {
                                            <button (click)="trackingService.trackEvent('share', 'native','', '',
                                                    trackingServiceSponsorship.getClientTrackerId());shareMobile()"
                                                    class="btn btn-social p-3 ms-2">
                                                <em aria-hidden="true" class="fas fa-share-alt fa-2x"></em>
                                            </button>
                                        }
                                        <button (cdkCopyToClipboardCopied)="trackingService.trackEvent('share', 'copy code','', '',
                                                trackingServiceSponsorship.getClientTrackerId());
                                                copyContent='Code Copié !'"
                                                [cdkCopyToClipboard]="urlToShare"
                                                class="d-none d-md-flex btn btn-social ms-2 py-3 px-4 flex-row">
                                            <em aria-hidden="true" class="fas fa-copy fa-2x"></em>
                                            <span class="ms-2 mt-2">{{copyContent}}</span>
                                        </button>
                                    </div>
                                    <div class="col d-flex d-md-none flex-row justify-content-center mt-2">
                                        <button (cdkCopyToClipboardCopied)="trackingService.trackEvent('share', 'copy code','', '',
                                                trackingServiceSponsorship.getClientTrackerId());
                                                copyContent='Code Copié !'"
                                                [cdkCopyToClipboard]="urlToShare"
                                                class="btn btn-social py-3 px-3 d-flex flex-row">
                                            <em aria-hidden="true" class="fas fa-copy fa-2x"></em>
                                            <span class="ms-2 mt-2">{{copyContent}}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
    
                    <div class="row">
                        <div class="col">
                            <div class="my-3 text-center bg-custom2 w-100 py-4 text-white h4">
                                Envoyez votre code parrain par email
                                @if (sponsorshipService.shareBySms && !platForm.ANDROID && !platForm.IOS) {
                                    <span> ou sms</span>
                                }
                            </div>
    
                            <div class="row mt-4 mb-3 pt-2">
                                <div class="col text-secondary px-5">
                                    L’email demeure une valeur sûre pour communiquer l’offre à vos proches.
                                </div>
                            </div>
    
                            <div class="text-center d-sm-none">
                                <a class="btn btn-primary mt-2 text-nowrap p-3 font-weight-normal" [href]="mobileMailToLink" data-cy="shareByEmail">Partager par email</a>
                            </div>
    
                            <form (ngSubmit)="submit()" [formGroup]="inviteForm" class="d-none d-sm-block">
                                @if (isContactApiAvailable()) {
                                    <div class="row mt-4 mb-3 mx-5">
                                        <div class="col text-center mb-2">
                                            <div class="mx-auto">
                                                <button (click)="contactPick()"
                                                        class="btn btn-primary py-2 w-100 font-weight-normal">
                                                    J'invite mes contacts
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
    
                                <div class="px-4" formArrayName="sponsoredList">
                                    @for (sponsoredForm of sponsoredList; track i; let i = $index) {
                                        <div class="text-start">
                                            <div [formGroupName]="i">
                                                <div class="row">
                                                    <div class="form-group col-12 col-lg-2 text-center my-2">
                                                        <label class="d-none" for="sponsor-{{i}}">Filleul </label>
                                                        <input class="form-control bg-custom text-center" disabled
                                                               id="sponsor-{{i}}"
                                                               placeholder="Filleul {{i + 1}}" type="text">
                                                    </div>
    
                                                    <div [ngClass]="sponsorshipService.shareBySms ? 'col-lg-2' : 'col-lg-3'"
                                                         class="form-group col-12 col-md-6 text-center my-2">
                                                        <label class="d-none" for="firstName-{{i}}">Prénom </label>
                                                        <input class="form-control text-center" formControlName="firstName"
                                                               id="firstName-{{i}}"
                                                               placeholder="Prénom"
                                                               type="text">
                                                        <app-form-errors
                                                                [control]="sponsoredList[i].get('firstName')" />
                                                    </div>
    
                                                    <div [ngClass]="sponsorshipService.shareBySms ? 'col-lg-2' : 'col-lg-3'"
                                                         class="form-group col-12 col-md-6 text-center my-2">
                                                        <label class="d-none" for="lastName-{{i}}">Nom </label>
                                                        <input class="form-control text-center" formControlName="lastName"
                                                               id="lastName-{{i}}"
                                                               placeholder="Nom"
                                                               type="text">
                                                        <app-form-errors
                                                                [control]="sponsoredList[i].get('lastName')" />
                                                    </div>
    
                                                    <div [ngClass]="sponsorshipService.shareBySms ? 'col-lg-3' : 'col-lg-4'"
                                                         class="form-group col-12 text-center my-2">
                                                        <label class="d-none" for="email-{{i}}">Adresse e-mail</label>
                                                        <input class="form-control text-center" formControlName="email"
                                                               id="email-{{i}}"
                                                               maxlength="320"
                                                               placeholder="Email" type="text">
                                                        <app-form-errors
                                                                [control]="sponsoredList[i].get('email')" />
                                                    </div>
    
                                                    @if (sponsorshipService.shareBySms) {
                                                        <div class="form-group col-12 col-lg-3 text-center my-2">
                                                            <label class="d-none" for="mobile-{{i}}">Numéro de téléphone</label>
                                                            <input class="form-control text-center" formControlName="mobile"
                                                                   id="mobile-{{i}}"
                                                                   placeholder="Numéro de téléphone" type="tel">
                                                            <app-form-errors
                                                                    [control]="sponsoredList[i].get('mobile')" />
                                                        </div>
                                                    }
                                                </div>
    
                                            </div>
                                        </div>
                                    }
                                </div>
    
                                @if (sponsorshipService.shareBySms) {
                                    <div class="form-group text-justify text-center my-2 px-2">
                                        <mat-checkbox class="text-wrap my-2"
                                                      color="primary"
                                                      formControlName="check"
                                                      id="check"
                                                      required>
                                            <mat-label class="px-2 clickable text-wrap">
                                                J'ai reçu l'accord de mon filleul et j'autorise
                                                {{sponsorshipService.getContent().clientName}} à contacter mon / ma filleule
                                            </mat-label>
                                        </mat-checkbox>
                                        <app-form-errors [control]="inviteForm.controls.check" />
                                    </div>
                                }
    
                                <div class="mb-4 px-3 text-end">
                                    <a (click)="addSponsored()">
                                        <em aria-hidden="true" class="fas fa-plus-circle custom-text"></em>
                                        <span class="custom-text clickable"> Ajouter un nouveau filleul</span>
                                    </a>
                                </div>
    
                                @if (sponsorshipService.getSettings().allowSponsorEmailCustomization) {
                                    <div class="px-4">
                                        <div class="form-group">
                                            <textarea
                                                    class="form-control p-4 text-secondary"
                                                    contenteditable
                                                    formControlName="text"
                                                    id="sponsorMsg"
                                                    rows="6">
                                            </textarea>
                                            <input formControlName="text" type="hidden" value="">
                                        </div>
                                        <div>
                                            <app-form-errors [control]="inviteForm.controls.text" />
                                        </div>
    
                                        <div class="custom-text text-center text-small mb-3">
                                            Vous pouvez personnaliser le message en cliquant dans le corps du texte.
                                        </div>
                                    </div>
                                }
    
                                @if (sponsorshipService.explicitSponsorAuthorizationContactSponsor) {
                                    <div>
                                        <div class="form-group text-justify text-center mt-3 px-2">
                                            <mat-checkbox color="primary"
                                                          formControlName="rgpd"
                                                          id="rgpd">
                                                <mat-label class="px-2 clickable text-wrap">
                                                    Le parrain certifie avoir l’accord du/de la filleul(e) susmentionné(e) pour
                                                    transmettre ses coordonnées et être recontacté(e) dans le cadre de son
                                                    parrainage.*
                                                </mat-label>
                                            </mat-checkbox>
                                        </div>
                                        <div>
                                            <app-form-errors [control]="inviteForm.controls.rgpd" />
                                        </div>
                                    </div>
                                }
    
                                @if (inviteForm.invalid && sent) {
                                    <div class="text-center">
                                        @if (countSponsored === 0) {
                                            <p class="text-danger">Veuillez renseigner au moins un filleul</p>
                                        }
                                    </div>
                                }
    
                                <div class="text-center">
                                    @if (!loadingSendingInvites) {
                                        <button class="btn btn-primary mt-2 text-nowrap p-3 font-weight-normal"
                                                id="submit"
                                                name="button"
                                                type="submit">
                                            Envoyer
                                        </button>
                                    } @else {
                                        <button class="btn btn-primary mt-2 px-4 py-3" disabled>
                                            <em aria-hidden="true" class="fas fa-spinner fa-spin"></em>
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        }
    
        @if (!isAllowedToInvite && !loadingSendingInvites) {
            <div class="col-12 col-md-10 col-xl-8 mx-auto g-0 py-4 px-0 invite">
                <div class="row my-3">
                    <div class="col text-center">
                        <h3 class="h3 mt-2">Nombre maximum de filleuls atteint</h3>
                        <button class="btn btn-gold my-2 text-nowrap p-3 font-weight-normal"
                                routerLink="/parrain/compte/historique"
                                type="button">
                            Suivi de vos Parrainages
                        </button>
                    </div>
                </div>
            </div>
        }
    </div>
}
<div id="email"></div>

@if (legalNoticeSpecificStep2) {
    <section class="mt-5">
        <div class="col-xs-11 m-5">
            <p [innerHTML]="legalNoticeSpecificStep2" class="text-justify px-1 legal-notice"></p>
        </div>
    </section>
}
<app-footer />
