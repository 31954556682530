import {Component, Inject} from '@angular/core';
import {TrackingServiceSponsorship} from '../../../shared/services/tracking-service-sponsorship.service';
import {ActivatedRoute} from '@angular/router';
import {SponsorshipService} from '../../../shared/services/sponsorship.service';
import {SponsorshipContent, TrackingService} from '@isifid/core';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html'
})

export class CookiesComponent {
    url: string;
    clientName: string;
    isTracked: boolean = true;

    constructor(
        private trackingServiceSponsorship: TrackingServiceSponsorship,
        private trackingService: TrackingService,
        private sponsorshipService: SponsorshipService,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.trackingService.trackCurrentPage(this.activatedRoute.snapshot, this.trackingServiceSponsorship.getClientTrackerId()).subscribe();
        const content: SponsorshipContent = this.sponsorshipService.getContent();
        if (content) this.clientName = content.clientName;
        else this.clientName = 'la société';

        this.isTracked = trackingServiceSponsorship.isTracked();
        this.url = this.document.defaultView.location.origin;
    }

    track(): void {
        this.trackingServiceSponsorship.startTracking();
        this.isTracked = true;
    }

    untrack(): void {
        this.trackingServiceSponsorship.stopTracking();
        this.isTracked = false;
    }
}
