import {Component, OnInit} from '@angular/core';
import {BootstrapService} from './shared/services/bootstrap.service';
import {AuthenticationService} from '@isifid/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    isAuth: boolean;
    isInitialised: boolean;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly bootstrapService: BootstrapService
    ) {
        this.isAuth = false;
        this.isInitialised = false;
    }

    ngOnInit() {
        this.authenticationService.isAuth.subscribe({next: (isAuth) => this.isAuth = isAuth});
        this.bootstrapService.isInitialised.subscribe({next: (isInitialised) => this.isInitialised = isInitialised});
    }

    isAppReady() {
        if (this.isInitialised) return true;
        if (!this.isAuth) return true;
        return false;
    }
}
