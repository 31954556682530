import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormErrorsComponent} from './components/form-errors/form-errors.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {SponsorshipService} from './services/sponsorship.service';
import {TrackingServiceSponsorship} from './services/tracking-service-sponsorship.service';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MsClientsService, MsConsumersService, MsServicesSponsorshipService} from '@isifid/core';
import {SponsorService} from './services/sponsor.service';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({ declarations: [
        HeaderComponent,
        FooterComponent,
        FormErrorsComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        FormErrorsComponent
    ], imports: [CommonModule,
        RouterModule,
        MatFormFieldModule], providers: [
        MsClientsService,
        MsConsumersService,
        MsServicesSponsorshipService,
        SponsorshipService,
        SponsorService,
        TrackingServiceSponsorship,
        provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
    ] })
export class SharedModule {
}
