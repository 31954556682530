import {Component, Input} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';

@Component({
    selector: 'app-form-errors',
    templateUrl: './form-errors.component.html'
})
export class FormErrorsComponent {
    private static readonly errorMessages = {
        required: () => 'Ce champ est requis.',
        requiredAgency: () => 'Merci de sélectionner une agence sur la carte au-dessus.',
        email: () => 'Veuillez saisir une adresse email valide.',
        minlength: (params) => `Ce champ doit contenir au moins ${params.requiredLength} caractères`,
        maxlength: (params) => `Ce champ ne doit pas contenir plus de ${params.requiredLength} caractères`,
        min: (params) => `La valeur ne peut pas être inférieur à ${params.min}`,
        max: (params) => `La valeur ne peut pas être supérieur à ${params.max}`,
        pattern: () => ` Le code personnalisé ne peut contenir que des lettres et des chiffres.`,
        matDatepickerMin: () => 'Date de naissance non éligible à l\'offre.',
        matDatepickerMax: () => 'Date de naissance non éligible à l\'offre.',
        number: () => 'Veuillez saisir un nombre valide.',
        phoneNumber: () => 'Veuillez saisir un numéro de téléphone valide.',
        isFrenchMobilePhone: () => 'Veuillez saisir un numéro de téléphone mobile valide.',
        isName: () => 'Le format n\'est pas valide',
        alreadyUsed: () => 'Ce code est déjà pris, merci d’en utiliser un autre.'
    };

    @Input()
    readonly control: AbstractControlDirective | AbstractControl;

    private static getMessage(type: string, params: any) {
        return FormErrorsComponent.errorMessages[type](params);
    }

    shouldShowErrors(): boolean {
        return this.control?.errors &&
            (this.control.dirty || this.control.touched);
    }

    listOfErrors(): Array<{ msg: string }> {
        // Filter to only show one message for date
        return Object.keys(this.control.errors)
            .filter(field => field !== 'matDatepickerParse').map(
                field => {
                    const error = {msg: ''};
                    error.msg = FormErrorsComponent.getMessage(field, this.control.errors[field]);
                    return error;
                });
    }
}
